// import useCookies from './useCookies';

function useCheckSSO() {
  // const cookies = useCookies();
  const { SSO, ORIGINAPP } = {
    SSO: false,
    ORIGINAPP: "humatrix",
  };
  const originApp = ORIGINAPP?.toLowerCase();

  return {
    isActive: Boolean(SSO),
    originApp,
  };
}

export default useCheckSSO;
