import generateCacheCrud from './generateCacheCrud';

const isWPMobileCache = generateCacheCrud('IS_WP_MOBILE')();

function isWorkplazeMobile() {
    if (isWPMobileCache.get()) return true;

    const [, querySearch] = window.location.href.split('?') || ['', ''];
    const urlSearchParams = new URLSearchParams(querySearch);

    const isOriginAppWpMobile = urlSearchParams.get('origin_app') === 'wp_mobile' || urlSearchParams.get('originapp') === 'wp_mobile';
    const isHashIncludesWpMobile = window.location.hash?.includes('#workplazemobile');

    if (isOriginAppWpMobile || isHashIncludesWpMobile) {
        // isWPMobileCache.set(true);
        return true;
    }

    return false;
}

export default isWorkplazeMobile;
