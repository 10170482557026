import React from 'react';
import { WPZIconName } from './interface';

type WPZIconProps = {
  iconName: WPZIconName;
  style?: React.CSSProperties;
  className?: string;
};

const WPZIcon: React.FC<WPZIconProps> = ({
  iconName,
  style,
  className = '',
}) => {
  const iconNameWithPrefix = iconName.startsWith('icon-')
    ? iconName
    : `icon-${iconName}`;
    
  return (
    <span
      role="img"
      aria-label={iconNameWithPrefix}
      className={`${iconNameWithPrefix} currentColor ${className}`}
      style={{ display: 'block', ...(style && style) }}
    />
  );
};

export default WPZIcon;
