import { useState, useEffect } from 'react';
import './style/ShowToastNotificationResult.less';

const NotificationDuration = ({ autoCloseSecond, type }) => {
  const [width, setWidth] = useState(100);

  useEffect(() => {
    window.setTimeout(() => {
      setWidth(0);
    }, 100);
  }, []);

  return (
    <div
      className={`notification-progress ${type}`}
      style={{
        width: `${width}%`,
        transition: `all ${autoCloseSecond}s ease-in-out`,
      }}
    />
  );
};

export default NotificationDuration;
