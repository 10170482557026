import { Flex, Typography } from 'antd';
import { LoginPinContext, LoginPinProvider } from './context/LoginPinContext';
import React, { useContext, useRef, useState } from 'react';

import LoginPinForm from './LoginPinForm';
import SecurityModal from '../security-modal';
import WPZIcon from '../../wpz-icon/WPZIcon';
import changePasswordImage from '@/lib/assets/img/wp-change-pass-illustration.png';
import { useTranslate } from '../../../hooks/useResourceKey';

// Props for the LoginPinModal component
type LoginPinModalProps = {
  isVisible: boolean;
  onCancel: () => void;
  onResponse: (values: { isUnlocked: boolean }) => void;
  type: 'Page' | 'Area';
  targetPath?: string;
};

const LoginPinModal: React.FC<LoginPinModalProps> = ({
  isVisible,
  onCancel,
  onResponse,
  type,
  targetPath,
}) => {
  // Translation labels
  const labelLoginPin = useTranslate('PleaseInputPIN', 'Please input your PIN');
  const labelSubmit = useTranslate('Submit', 'Submit');

  // State to manage loading status
  const [loading, setLoading] = useState(false);
  // Reference to the form component
  const formRef = useRef<any>(null);

  // Handle form submission
  const handleFormSubmit = () => {
    if (formRef.current) {
      formRef.current.submit();
    }
  };

  return (
    <>
      <LoginPinProvider>
        <SecurityModal
          title={
            <Flex align="middle" gap={'small'}>
              <WPZIcon iconName="icon-lock" />
              <Typography>{labelLoginPin}</Typography>
            </Flex>
          }
          isVisible={isVisible}
          onSubmit={handleFormSubmit}
          submitButtonText={labelSubmit}
          isCloseable={true}
          isSubmitLoading={loading}
          onCancel={onCancel}
          imageUrl={changePasswordImage}
        >
          <LoginPinForm
            ref={formRef}
            onResponse={onResponse}
            setLoading={setLoading}
            loading={loading}
            type={type}
            targetPath={targetPath}
          />
        </SecurityModal>
      </LoginPinProvider>
    </>
  );
};

export default LoginPinModal;
