import ChangePasswordForm from '../change-password-form';
import { FormInstance } from 'antd';
import { PasswordValidationSetting } from '../password-validator/PasswordValidator';
import WPForm from '../../wp-form/WPForm';
import { useTranslate } from '@/lib/hooks/useResourceKey';

interface ChangePinFormProps {
  formInstance: FormInstance;
  passwordValidationSetting: PasswordValidationSetting;
  onSubmit: (values: any) => void;
}

const ChangePinForm: React.FC<ChangePinFormProps> = ({
  formInstance,
  passwordValidationSetting,
  onSubmit,
}) => {
  const labelNewPin = useTranslate('NewPin', 'New Pin');
  const labelConfirmPin = useTranslate('ConfirmPin', 'Confirm Pin');

  return (
    <WPForm form={formInstance} layout="vertical" onFinish={onSubmit}>
      <ChangePasswordForm
        newPasswordLabel={labelNewPin}
        confirmPasswordLabel={labelConfirmPin}
        formType="forgot"
        passwordValidationSetting={
          passwordValidationSetting || ({} as PasswordValidationSetting)
        }
        securitySetting="PIN"
      />
    </WPForm>
  );
};

export default ChangePinForm;
