import { Fragment, Suspense, lazy } from 'react';

import LoginThemesLayout from '../themes/LoginThemeLayout';
import { Spin } from 'antd';
import { useLoginState } from '../contexts/LoginStateProvider';

const authForms = {
    login: lazy(() => import('./FormLogin')),
};

const LoginLayout = () => {
    const { selectedFormType } = useLoginState();
    const SelectedForm = authForms[selectedFormType] || Fragment;

    return (
        <LoginThemesLayout themeName="workplaze">
            <div className="sf7-login-form WP-login__form">
                <Suspense
                    fallback={
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <Spin />
                        </div>
                    }
                >
                    <SelectedForm />
                </Suspense>
            </div>
        </LoginThemesLayout>
    );
};

export default LoginLayout;
