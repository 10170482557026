import CryptoJS from "crypto-js";

const KEY = process.env.REACT_APP_KEY || "WPZ-TEST-KEY";

type CryptographyParams = {
  value?: unknown | string;
  key?: string;
  stringify?: boolean;
  parse?: boolean;
};

export const encrypt = ({
  value = "",
  key = KEY,
  stringify = true,
}: CryptographyParams) => {
  const valueToEncrypt = stringify ? JSON.stringify(value) : (value as string);
  return CryptoJS.AES.encrypt(valueToEncrypt, key).toString();
};

export const decrypt = ({
  value,
  key = KEY,
  parse = true,
}: CryptographyParams) => {
  if (!value || typeof value !== "string") return false;
  const result = CryptoJS.AES.decrypt(value, key).toString(CryptoJS.enc.Utf8);
  if (parse) {
    try {
      return JSON.parse(result);
    } catch (error) {
      console.error("Decrypt Error:", error);
      return false;
    }
  }
  return result;
};
