import useCheckSSO from "@/hooks/useCheckSSO";
import "assets/styles/App.less";
import useCustomTheme from "@/hooks/useCustomTheme";
import WPThemeSelectorContext from "./context/WPThemeSelectorContext";

const WPThemeSelector: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { isActive: isSSOActive, originApp } = useCheckSSO();

  return (
    <WPThemeSelectorContext.Provider
      value={{
        ...useCustomTheme({
          isSSOActive,
          originApp,
          staticTheme: { primaryColor: "#395ebc" },
        }),
      }}
    >
      {children}
    </WPThemeSelectorContext.Provider>
  );
};

export default WPThemeSelector;
