import './css/modal.css';

import { Modal as AntModal, Button, Col, Row } from 'antd';
import React, { useCallback, useMemo } from 'react';

import { useTranslate } from '../../../hooks/useResourceKey';

interface SecurityModalProps {
  isVisible: boolean;
  onSubmit: () => void;
  submitButtonText: string;
  isCloseable?: boolean;
  onCancel?: () => void;
  isLoading?: boolean;
  isSubmitLoading?: boolean;
  title: string | React.ReactNode;
  children?: React.ReactNode;
  imageUrl?: string;
  additionalButton?: React.ReactNode;
}

const SecurityModal: React.FC<SecurityModalProps> = ({
  isVisible,
  onSubmit,
  submitButtonText,
  isCloseable = false,
  onCancel,
  isLoading = false,
  isSubmitLoading = false,
  title,
  children,
  imageUrl,
  additionalButton,
}) => {
  const handleSubmit = useCallback(() => {
    onSubmit();
  }, [onSubmit]);

  const handleCancel = useCallback(() => {
    if (isCloseable && onCancel) {
      onCancel();
    }
  }, [isCloseable, onCancel]);

  const renderFooterButtons = useMemo(() => {
    const buttons: React.ReactNode[] = [];
    if (additionalButton) {
      buttons.push(additionalButton);
    }
    buttons.push(
      <Button
        key="submit"
        type="primary"
        loading={isSubmitLoading}
        onClick={handleSubmit}
      >
        {submitButtonText}
      </Button>
    );

    return buttons;
  }, [isSubmitLoading, handleSubmit, submitButtonText, additionalButton]);

  return (
    <AntModal
      className="scrollable-modal"
      title={title}
      open={isVisible}
      onCancel={handleCancel}
      closable={isCloseable}
      loading={isLoading}
      maskClosable={false}
      centered
      width={720}
      footer={renderFooterButtons}
    >
      <div
        style={{
          height: '100%',
          maxHeight: '612px',
          overflowY: 'auto',
        }}
      >
        <Row justify="center">
          {imageUrl && (
            <Col
              span={24}
              style={{ textAlign: 'center', marginBottom: '16px' }}
            >
              <img
                src={imageUrl || ''}
                alt="Modal Image"
                style={{
                  maxWidth: '100%',
                  height: 'auto',
                  width: '550px',
                  maxHeight: '250px',
                }}
              />
            </Col>
          )}
        </Row>
        <Row justify="center">
          <Col xs={24} sm={20} md={16} lg={14} xl={14}>
            {children}
          </Col>
        </Row>
      </div>
    </AntModal>
  );
};

export default SecurityModal;
