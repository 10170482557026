import languageInstance from '@/lib/i18n';

const useSwitchLanguage = () => {
    const switchContainerLanguage = (language: string) => {
        languageInstance.changeLanguage(language);
    };
    const switchAllLanguage = (language: string) => {
        switchContainerLanguage(language);
        import('standard/hooks/useSwitchLanguage').then(({ default: switchStandardLanguage }) => {
            switchStandardLanguage()(language);
        });
    };

    return {
        switchContainerLanguage,
        switchAllLanguage,
    };
};

export default useSwitchLanguage;
