import axios, {
  AxiosError,
  AxiosRequestHeaders,
  AxiosResponse,
  InternalAxiosRequestConfig,
} from 'axios';

import { LANG_CODE } from './constant';
import { RefreshToken } from '../redux/actions/globalAction';
import { store } from '../redux/storeExportType';
import useCookies from '@/lib/hooks/useCookies';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import useGetMainCookie from '../hooks/useGetMainCookie';

const { getCookies } = useCookies();

export const onFulfilledResponse = (response: AxiosResponse) => response;

export const onRejectedResponse = (error: AxiosError) => Promise.reject(error);

const axiosInstance = axios.create({
  baseURL: `${process.env.VITE_APP_ENDPOINT_MY_WORKPLAZ}`,
  headers: {
    'Content-Type': 'application/json',
  },
});

let requestRetryCount = 0;
const MAX_RETRY_COUNT = 1;

const setHeader = (
  axiosConfig: InternalAxiosRequestConfig
): InternalAxiosRequestConfig => {
  const tokenCookie = getCookies('jwtToken', true);
  const pinToken = getCookies('pinToken', true);
  const newHeaders = tokenCookie
    ? {
        Authorization: `Bearer ${tokenCookie.token}`,
        Language: LANG_CODE(),
        Pintoken: pinToken?.token,
      }
    : {};

  // Merge the existing headers with the new headers
  const headers: AxiosRequestHeaders = {
    ...axiosConfig.headers,
    ...newHeaders,
  } as AxiosRequestHeaders;

  return { ...axiosConfig, headers };
};

axiosInstance.interceptors.request.use(
  (config: InternalAxiosRequestConfig) => setHeader(config),
  onRejectedResponse
);

const AxiosInterceptor = ({ children }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    const responseIntercept = axiosInstance.interceptors.response.use(
      (response) => {
        requestRetryCount = 0;
        return response;
      },
      async (error) => {
        const cookies = useGetMainCookie();
        const {
          status: statusCode,
          data: response,
          config: originalRequest,
        } = error.response ?? {};

        if (
          statusCode === 401 &&
          store.getState().global.refreshTokenLoading === false &&
          cookies &&
          requestRetryCount < MAX_RETRY_COUNT
        ) {
          requestRetryCount += 1;
          await RefreshToken()(dispatch);
          return axiosInstance(setHeader(originalRequest));
        }

        if (requestRetryCount >= MAX_RETRY_COUNT) {
          return Promise.reject(response);
        }
        return Promise.reject(response);
      }
    );

    return () => axiosInstance.interceptors.response.eject(responseIntercept);
  }, []);
  return children;
};

export default axiosInstance;
export { AxiosInterceptor };
