import localforage from 'localforage';
import moment from 'moment';
import axiosInstance from '../helpers/axiosInstance';
import { baseURL, DATE_TIME_YYYY_MM_DD_DASH } from '../helpers/constant';
import { useTranslation } from 'react-i18next';
import { decrypt, encrypt } from '../helpers/cryptography';
import { BaseResponse } from '../interfaces';
import enJSON from '../i18n/en.json';
import thJSON from '../i18n/th.json';

const apiGetResourceKey = `${baseURL}v1/sf7/hrm/payroll/wpx/get-resource-description`;
const apiGetResourceKeyMock = `/v1/sf7/hrm/payroll/wpx/get-resource-description`;

export type ResourceKeyResponse = {
  resourceList: Record<string, string>;
  messageDetails: {
    messageType: string;
    description: string;
  };
};

export const useResourceKey = () => {
  const {
    i18n: { language },
  } = useTranslation();

  const checkExpiredResourceKey = () => {
    const expired = getExpiredResourceKey();
    return moment().isBefore(
      moment(expired?.[language], DATE_TIME_YYYY_MM_DD_DASH)
    );
  };

  const getExpiredResourceKey = () => {
    const expiredResourceKey = localStorage.getItem('hmc_resource');
    return decrypt({ value: expiredResourceKey }) as unknown as Record<
      string,
      string
    >;
  };

  const getResourceKeys = async () => {
    try {
      const db = localforage.createInstance({
        name: `resource-description`,
      });
      return await db.getItem<Record<string, string>>(language);
    } catch {
      return null;
    }
  };

  const dropResourceKey = async () => {
    try {
      await localforage.dropInstance({
        name: `resource-description`,
      });
    } catch (error) {
      console.error('Failed to drop resource keys', error);
    }
  };

  const convertToUpperCase = (
    data: Record<string, string>
  ): Record<string, string> => {
    return Object.entries(data).reduce((acc, [key, value]) => {
      return {
        ...acc,
        [key.toUpperCase()]: value,
      };
    }, {} as Record<string, string>);
  };

  const fetchResourceKey = async () => {
    try {
      // const { data } = await axiosInstance.post<
      //   BaseResponse<ResourceKeyResponse>
      // >(`${apiGetResourceKeyMock}`, {
      //   language,
      // });

      let resourceListFromJson;

      switch (language) {
        case 'en':
          resourceListFromJson = enJSON;
          break;
        case 'th':
          resourceListFromJson = thJSON;
          break;
        default:
          resourceListFromJson = enJSON;
          break;
      }

      // const resourceList = convertToUpperCase(data?.DATA?.resourceList || {});
      const resourceList = convertToUpperCase(resourceListFromJson);

      onSuccess(resourceList);

      return resourceList;
    } catch (error) {
      console.error(error);
    }
  };

  const onSuccess = async (data: Record<string, string>) => {
    try {
      const db = localforage.createInstance({
        name: `resource-description`,
      });
      const resourceKeys = (await db.getItem(language)) as unknown as Record<
        string,
        string
      >;
      db.setItem(language, { ...resourceKeys, ...data });
      const expiredResourceKey = getExpiredResourceKey();
      const expiredObj = {
        ...expiredResourceKey,
        [language]: moment().add(1, 'weeks').format(DATE_TIME_YYYY_MM_DD_DASH),
      };
      const expiredEncrypt = encrypt({ value: expiredObj });
      localStorage.setItem('hmc_resource', expiredEncrypt);
    } catch (error) {
      console.error('Failed to save resource keys', error);
    }
  };

  const setLanguageToLocalStorage = (language: string) =>
    localStorage.setItem('language', language);

  const getLanguageFromLocalStorage = () => localStorage.getItem('language');

  return {
    fetchResourceKey,
    getResourceKeys,
    dropResourceKey,
    checkExpiredResourceKey,
    setLanguageToLocalStorage,
    getLanguageFromLocalStorage,
  };
};

export const useTranslate = (key: string, defaultValue?: string, interpolation?: Record<string, string>) => {
  const { t } = useTranslation();

  return t(key.toLocaleUpperCase(), {
    defaultValue: defaultValue || key,
    interpolation,
  });
};
