import { Form, FormProps } from 'antd';

import { FormInstance } from 'antd/lib';
import { PropsWithChildren } from 'react';
import WPFormLeaveGuard from './WPFromLeaveGuard';
import styled from 'styled-components';

const WPFormStyled = styled(Form)`
  .ant-form-item-label
    > label.ant-form-item-required:not(
      .ant-form-item-required-mark-optional
    )::before {
    display: none;
  }

  .ant-form-item-label
    > label.ant-form-item-required:not(
      .ant-form-item-required-mark-optional
    )::after {
    display: inline-block;
    margin-right: 4px;
    color: #f64e60;
    font-size: 14px;
    font-family: SimSong;
    line-height: 20px;
    content: '*';
    top: 3px;
    visibility: visible;
  }
`;

const WPForm: React.FC<
  PropsWithChildren &
    Omit<FormProps, 'form'> & { form: FormInstance; leaveGuard?: boolean }
> = ({ children, form, leaveGuard = true, ...props }) => {
  return (
    <WPFormStyled form={form} {...props}>
      {children}
      {WPFormLeaveGuard({ block: leaveGuard && form.isFieldsTouched() })}
    </WPFormStyled>
  );
};

export default WPForm;
