import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface GlobalState {
  refreshTokenLoading: boolean;
  refreshTokenSuccess: boolean|undefined;
  showModalReLogin: boolean;
  lastInteractive: boolean;

}

const initialState:GlobalState = {
  refreshTokenLoading: false,
  refreshTokenSuccess: undefined,
  showModalReLogin: false,
  lastInteractive: false,
}

export const GlobalReducer = createSlice({
  initialState: initialState,
  name: "global",
  reducers: {
    setRefreshTokenLoading: (state, action: PayloadAction<boolean>) => {
      state.refreshTokenLoading = action.payload;
    },
    setRefreshTokenSuccess: (state, action: PayloadAction<boolean | undefined>) => {
      state.refreshTokenSuccess = action.payload;
    },
    setShowModalReLogin: (state, action: PayloadAction<boolean>) => {
      state.showModalReLogin = action.payload;
    },
    setLastInteractive: (state, action: PayloadAction<boolean>) => {
      state.lastInteractive = action.payload;
    },
  }
})

export const {
  setRefreshTokenLoading,
  setRefreshTokenSuccess,
  setShowModalReLogin,
  setLastInteractive
} = GlobalReducer.actions;

export default GlobalReducer.reducer;
