import React from 'react';
import { useContext } from 'react';
import { ConfigProvider as AntdConfigProvider } from 'antd';
import enUS from 'antd/lib/locale/en_US';
import idID from 'antd/lib/locale/id_ID';
import msMY from 'antd/lib/locale/ms_MY';
import thTH from 'antd/lib/locale/th_TH';
import koKR from 'antd/lib/locale/ko_KR'; // korea
import viVN from 'antd/lib/locale/vi_VN'; // vietnam
import zhCN from 'antd/lib/locale/zh_CN'; // china
import { customizeTheme } from './customizeTheme';
// import LangContext from '../lang/LangContext';

// import { StyleProvider } from '@ant-design/cssinjs';

const LOCALE = {
  en: enUS,
  id: idID,
  my: msMY,
  th: thTH,
  kr: koKR,
  vn: viVN,
  cn: zhCN,
};

interface ConfigProviderProps {
  children: React.ReactNode;
}

const ConfigProvider: React.FC<ConfigProviderProps> = ({ children }) => {
  // const { userLanguage } = useContext(LangContext);
  // const activeLocale = userLanguage?.toLowerCase() || '';
  // const antdLocale = LOCALE[activeLocale] || LOCALE.en;
  const antdLocale = LOCALE.en;

  return (
    <>
      <AntdConfigProvider locale={antdLocale} theme={customizeTheme}>{children}</AntdConfigProvider>
      {/* <AntdConfigProvider locale={antdLocale} theme={{}}>{children}</AntdConfigProvider> */}
    </>
    // <AntdConfigProvider locale={antdLocale} theme={{}}>{children}</AntdConfigProvider>
  );
};

export default ConfigProvider;
