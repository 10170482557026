import { createContext, useContext } from "react";
import { LoginLayoutCustomProps } from "../types/loginType";

const initialValue: LoginLayoutCustomProps = {};

export function useLoginLayoutCustomContext() {
  return useContext(LoginLayoutCustomContext);
}
 
const LoginLayoutCustomContext = createContext(initialValue);


export default LoginLayoutCustomContext;
