import React from 'react';
import { Provider } from 'react-redux';
import ResourceDefinitionProvider from './contexts/resource-definition/ResourceDefinitionProvider';
import joinStore from './redux/joinStore';
import ConfigProvider from './config/ConfigProvider';
import { AxiosInterceptor } from './helpers/axiosInstance';
import ModalReLogin from './components/modal-re-login';



type SharedLibraryProps = React.PropsWithChildren & {
  customRedux?: any;
};

const SharedLibrary: React.FC<SharedLibraryProps> = ({
  children,
  customRedux,
}) => {
  const customReducer = customRedux !== undefined ? { ...customRedux } : {};
  const combine = joinStore(customReducer);

  return (
    <Provider store={combine}>
      <ConfigProvider>
        <AxiosInterceptor>
          <ModalReLogin>
            <ResourceDefinitionProvider>{children}</ResourceDefinitionProvider>
          </ModalReLogin>
        </AxiosInterceptor>
      </ConfigProvider>
    </Provider>
  );
};

export default SharedLibrary;
