import { Route } from '@/interfaces';
import { lazy } from 'react';

const SettingRoute: Route[] = [
    {
        path: 'settings/common.settings.resource-definition-master',
        Component: lazy(() => import('pages/settings/resource-definition')),
    },
];

export default SettingRoute;
