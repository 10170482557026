import LoginLayout from './components/LoginLayout';
import { LoginStateProvider } from './contexts/LoginStateProvider';
// import "assets/styles/login/Login.less";
import useCheckIsLogin from '@/hooks/useCheckIsLogin';
// import LoginLayout from "./components/LoginLayout";
// import MultiLoginContextProvider from "./context";
// import { useLoginConfig } from "./context/LoginConfigContext";
// import useCheckIsLogin from "./hooks/useCheckIsLogin";
// import useInitLoginConfig from "./hooks/useInitLoginConfig";
// import useLoginVerificationMessage from "./hooks/useLoginVerificationMessage";

const LoginPage = () => {
    // const { app_name } = useLoginConfig();

    // useInitLoginConfig();
    useCheckIsLogin();
    // useLoginVerificationMessage();

    // useEffect(() => {
    //   if (app_name) document.title = app_name;
    // }, []);

    return <LoginLayout />;
};

export default function Login() {
    return (
        <LoginStateProvider>
            <LoginPage />
        </LoginStateProvider>
    );
}

// export default function Login() {
//   return (
//     <MultiLoginContextProvider>
//       <LoginPage />
//     </MultiLoginContextProvider>
//   );
// }
