import { Col, Spin, Typography } from "antd";
import isWorkplazeMobile from "@/helpers/isWorkplazeMobile";

const WPLoadingComponent = ({
  style = {},
  loadingAuth = false,
  isLoadingAccess = false,
  ...props
}) => {
  return (
    <Col
      span={24}
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        position: "absolute",
        top: 0,
        bottom: 0,
        right: 0,
        left: 0,
        ...style,
      }}
    >
      <Spin {...props}>
        {isWorkplazeMobile() && (
          <div style={{ paddingTop: 50 }}>
            <>
              {loadingAuth || isLoadingAccess ? (
                <Typography.Text>
                  {loadingAuth
                    ? `Authenticating...`
                    : isLoadingAccess
                    ? `Loading User Access`
                    : ""}
                </Typography.Text>
              ) : (
                <Typography.Text style={{ textTransform: "capitalize" }}>
                  Loading {process.env.REACT_APP_TYPE} Resources...
                </Typography.Text>
              )}
            </>
          </div>
        )}
      </Spin>
    </Col>
  );
};

export default WPLoadingComponent;
