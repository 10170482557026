import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';

import { List } from 'antd';
import React from 'react';
import WPZIcon from '../../wpz-icon/WPZIcon';

export interface PasswordValidationSetting {
  upperCharacter: string;
  lowerCharacter: string;
  numericCharacter: string;
  specialCharacter: string;
  hasUpper: boolean;
  hasLower: boolean;
  hasNumeric: boolean;
  hasSpecial: boolean;
  passwordMinLength: string;
  passwordMaxLength: string;
}

interface PasswordValidatorProps {
  password: string;
  validationRules: PasswordValidationSetting;
  touched: boolean;
  type?: 'Password' | 'PIN';
}

const PasswordValidator: React.FC<PasswordValidatorProps> = ({
  password,
  validationRules,
  touched,
  type = 'password',
}) => {
  if (!validationRules) return null; // Do not render until validation rules are loaded

  const minLength = parseInt(validationRules.passwordMinLength || '0', 10);
  const maxLength = parseInt(validationRules.passwordMaxLength || '0', 10);

  const isValidLength =
    password.length >= minLength && password.length <= maxLength;
  const hasUpper = new RegExp(`[${validationRules.upperCharacter}]`).test(
    password
  );
  const hasLower = new RegExp(`[${validationRules.lowerCharacter}]`).test(
    password
  );
  const hasNumeric = new RegExp(`[${validationRules.numericCharacter}]`).test(
    password
  );
  const hasSpecial = new RegExp(`[${validationRules.specialCharacter}]`).test(
    password
  );

  // If min and max length are the same, show "Exactly X characters", otherwise "At length X-Y characters"
  const lengthLabel =
    minLength === maxLength && minLength > 0 && maxLength > 0
      ? `Exactly ${minLength} characters`
      : minLength > 0 && maxLength > 0
      ? `At length ${minLength}-${maxLength} characters`
      : minLength > 0
      ? `At least ${minLength} characters`
      : '';

  return touched ? (
    <div>
      <p style={{ fontWeight: 'bold' }}>
        {type === 'pin' ? 'PIN must contain:' : 'Password must contain:'}
      </p>
      <List
        size="small"
        dataSource={[
          {
            text: lengthLabel,
            valid: isValidLength,
          },
          {
            text: 'At least one uppercase letter',
            valid: hasUpper,
            show: type === 'password',
          },
          {
            text: 'At least one lowercase letter',
            valid: hasLower,
            show: type === 'password',
          },
          {
            text: 'At least one numeric character',
            valid: hasNumeric,
          },
          {
            text: `At least one special character (${validationRules.specialCharacter})`,
            valid: hasSpecial,
            show: type === 'password',
          },
        ].filter((item) => item.show !== false)} // Filter out items not to be shown
        renderItem={(item) => (
          <List.Item style={{ border: 'none' }}>
            {item.valid ? (
              <WPZIcon iconName="icon-x16Check" style={{ color: 'green' }} />
            ) : (
              <WPZIcon iconName="icon-x16Close" style={{ color: 'red' }} />
            )}
            {item.text}
          </List.Item>
        )}
      />
    </div>
  ) : null;
};

export default PasswordValidator;
