import { Input, Space } from 'antd';
import React, { useCallback, useEffect, useRef, useState } from 'react';

import type { FormItemProps } from 'antd';

// Define the props for the InputPin component
interface InputPinProps extends Omit<FormItemProps, 'name' | 'onChange'> {
  length?: number; // The length of the pin
  isDisabled?: boolean; // Whether the input is disabled
  isPassword?: boolean; // Whether the input type is password
  autoFocus?: boolean; // Whether to auto focus the first input on mount
  onChange?: (value: string) => void; // Function to call when the input value changes
  value?: string; // The current value of the input
}

// Default length of the pin
const DEFAULT_LENGTH = 6;

// Width of each input field
const INPUT_SIZE = '48px';

// Function to split the value into an array of characters
// and fill the rest of the array with empty strings up to the specified length
const splitValueIntoArray = (value: string, length: number) => {
  return value.split('').concat(Array(length).fill('')).slice(0, length);
};

// The InputPin component
const InputPin: React.FC<InputPinProps> = React.memo(
  ({
    length = DEFAULT_LENGTH,
    isDisabled = false,
    isPassword = false,
    autoFocus = false,
    onChange,
    value,
    ...restProps
  }) => {
    // Initialize the state for the input values
    const [values, setValues] = useState<string[]>(
      value ? splitValueIntoArray(value, length) : Array(length).fill('')
    );

    // Initialize the ref for the input elements
    const inputRefs = useRef<(HTMLInputElement | null)[]>([]);

    // Initialize the state for the active input index
    const [activeIndex, setActiveIndex] = useState<number | null>(null);

    // Focus the first input on mount if autoFocus is true
    useEffect(() => {
      if (autoFocus && inputRefs.current[0]) {
        inputRefs.current[0].focus();
      }
    }, [autoFocus]);

    // Update the input values when the value prop changes
    useEffect(() => {
      if (value !== undefined) {
        setValues(splitValueIntoArray(value, length));
      }
    }, [value, length]);

    // Handle input change
    const handleChange = useCallback(
      (index: number, inputValue: string) => {
        // Update the values state and call the onChange prop with the new value
        setValues((prev) => {
          const newValues = [...prev];
          newValues[index] = inputValue;
          const newJoinedValue = newValues.join('');
          onChange?.(newJoinedValue);
          return newValues;
        });

        // Focus the next input if the current input value is not empty
        if (inputValue && index < length - 1) {
          inputRefs.current[index + 1]?.focus();
        }
      },
      [length, onChange]
    );

    // Handle key down event
    const handleKeyDown = useCallback(
      (index: number, e: React.KeyboardEvent<HTMLInputElement>) => {
        // Focus the previous input if the backspace key is pressed and the current input value is empty
        if (e.key === 'Backspace' && !values[index] && index > 0) {
          inputRefs.current[index - 1]?.focus();
        }
        // Focus the previous input if the left arrow key is pressed
        else if (e.key === 'ArrowLeft' && index > 0) {
          inputRefs.current[index - 1]?.focus();
        }
        // Focus the next input if the right arrow key is pressed
        else if (e.key === 'ArrowRight' && index < length - 1) {
          inputRefs.current[index + 1]?.focus();
        }
      },
      [values, length]
    );

    // Handle paste event
    const handlePaste = useCallback(
      (e: React.ClipboardEvent<HTMLInputElement>) => {
        // Prevent the default paste action
        e.preventDefault();

        // Get the pasted data, filter out non-numeric characters, split it into an array of characters,
        // fill the rest of the array with empty strings up to the specified length, and update the values state
        const pastedData = e.clipboardData
          .getData('text')
          .replace(/\D/g, '')
          .slice(0, length);
        const newValues = pastedData
          .split('')
          .concat(Array(length).fill(''))
          .slice(0, length);
        setValues(newValues);
        onChange?.(newValues.join(''));

        // Focus the last input that was filled with the pasted data
        inputRefs.current[Math.min(pastedData.length, length - 1)]?.focus();
      },
      [length, onChange]
    );

    // Render the input elements
    return (
      <Space size={'small'}>
        {values.map((val, index) => (
          <Input
            style={{
              width: INPUT_SIZE,
              height: INPUT_SIZE,
              textAlign: 'center',
            }}
            key={index}
            ref={(el) =>
              (inputRefs.current[index] = el as HTMLInputElement | null)
            }
            value={val}
            onChange={(e) => handleChange(index, e.target.value)}
            onKeyDown={(e) => handleKeyDown(index, e)}
            onPaste={handlePaste}
            onFocus={() => setActiveIndex(index)}
            onBlur={() => setActiveIndex(null)}
            maxLength={1}
            disabled={isDisabled}
            type={isPassword ? 'password' : 'text'}
          />
        ))}
      </Space>
    );
  }
);

export default InputPin;
