import { Col, Grid, Image, Row } from 'antd';

import { CustomLayoutStyled } from './CustomLayoutStyled';
import LanguageSelector from 'components/global/lang/LangSelector';
import workplazeLogo from 'assets/img/logo/workplaze-logo-5.png';

type CustomLayoutProps = {
    children: JSX.Element;
};

const CustomLayout: React.FC<CustomLayoutProps> = ({ children }) => {
    const screenBreakPoints = Grid.useBreakpoint(); // Get screen breakpoints for responsive design

    return (
        <>
            <CustomLayoutStyled className="WP-login" {...screenBreakPoints}>
                {/* Header section with language selector */}
                <div className="WP-login__header">
                    <LanguageSelector withBorder={false} />
                </div>

                {/* Main container with logo and children components */}
                <div className="WP-login__container">
                    <Row justify="center" align="middle">
                        {/* Centered WorkplazeLogo */}
                        <Col span={24}>
                            <Row justify="center" style={{ marginBottom: 56 }}>
                                <Image preview={false} className="image company-logo" alt="Workplaze" src={workplazeLogo} width={150} />
                            </Row>
                        </Col>
                        {/* Container body with children components */}
                        <Col span={24}>
                            <div className="WP-login__container__body">{children}</div>
                        </Col>
                    </Row>
                </div>

                {/* Footer section with copyright text */}
                <div className="WP-login__footer">
                    <span className="WP-login__footer__text">{`©Workplaze™. All Rights Reserved ${new Date().getFullYear()}`}</span>
                </div>
            </CustomLayoutStyled>
        </>
    );
};

export default CustomLayout;
