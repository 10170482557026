import styled, { css } from 'styled-components';

import { Layout } from 'antd';
import WorkplazeLoginBackground from '@/assets/img/wp-login-bg.png';

export const CompanyLogoWrapper = styled.div`
    text-align: center;
    padding: 0 16px;
    margin-bottom: 16px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 12px;

    > img {
        max-height: 54px;
    }

    .ant-typography {
        color: var(--ant-primary-color-active);
    }
`;

type CustomLayoutStyledProps = {
    lg?: boolean;
    isCustomTheme?: boolean;
};

export const CustomLayoutStyled = styled(Layout)<CustomLayoutStyledProps>`
    &.WP-login {
        background-attachment: fixed;
        align-items: center;
        background-size: cover;
        background-position: center;
        padding: 8px 12px;
        justify-content: space-around;
        min-height: 100vh;
        input:-internal-autofill-selected {
            background-color: --white;
        }

        ${({ lg, isCustomTheme }) => {
            //   const { backgroundUrl, backgroundColor, smallBackgroundImageUrl } =
            //     useLoginLayoutCustomContext();
            //   const fallbackSmallBackgroundImage =
            //     smallBackgroundImageUrl || backgroundUrl;

            if (!isCustomTheme) {
                return css`
                    background-image: url(${WorkplazeLoginBackground});
                `;
            }

            return !lg
                ? css`
                      background-image: url(${WorkplazeLoginBackground});
                  `
                : css`
                      background-image: url(${WorkplazeLoginBackground});
                  `;
        }}

        .WP-login__title {
            margin-bottom: 16px;
        }

        .WP-login__header,
        .WP-login__footer {
            margin-left: auto;
            margin-right: auto;
        }

        .WP-login__header {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            width: 100%;
            gap: 16px;
            padding: 0px 48px;
            .ant-select-selection-item {
                font-weight: 800;
            }

            ${({ lg }) =>
            !lg &&
            css`
                padding: 0px 0px;
            `}
            &__title img {
                height: 48px;
                width: auto;
            }

            &__helpdesk {
                background-color: rgba(255, 255, 255, 0.5);
                padding: 0px 16px;
                font-size: 12px;
                height: 34px;
                border: none;
                border: 1px solid var(--white);
                color: var(--text-color);
            }
            .login-language-selector {
                display: flex;
                justify-content: center;
                align-items: center;
                background: rgba(255, 255, 255, 0.5);
                border: 1px solid var(--white);
                border-radius: 99px;
                padding-right: 8px;
                padding-left: 4px;
                color: var(--text-color);

                .ant-select {
                    font-size: 12px !important;
                    width: unset !important;
                    font-weight: 800;

                    + .anticon {
                        position: relative !important;
                        top: unset !important;
                        right: unset !important;
                        margin-left: -8px;
                        font-size: 12px;
                        padding: 4px;
                    }

                    .ant-select-selector {
                        background-color: unset;
                        border: none;
                    }

                    &.ant-select-focused {
                      .ant-select-selector {
                        box-shadow: unset;
                      }
                    }
                }
            }
        }

        .bg-login-illustration {
            border-top-left-radius: 16px;
            border-top-right-radius: 16px;
            padding: 32px 24px;
            margin-top: -24px;

            img {
                max-width: 100%;
            }

            .slick-dots-bottom {
                bottom: -16px !important;
                margin: 0;

                li {
                    &.slick-active button {
                        background-color: var(--primary-color);
                    }

                    button {
                        background-color: var(--ant-primary-4);
                    }
                }
            }

            ${({ lg }) =>
                !lg &&
                css`
                    background: var(--white);
                    margin-bottom: 24px;
                    margin-top: -55px;
                `}
        }

        .WP-login__container {
            display: flex;
            width: 100%;
            max-width: 448px;
            ${({ lg }) =>
                !lg &&
                css`
                    max-width: 460px;
                    padding-top: 24px;
                    padding-bottom: 24px;

                    ${CompanyLogoWrapper} {
                        display: none;
                    }
                `}

            > .ant-row {
                margin-top: 30px;
                margin-bottom: 32px;
                background: rgba(255, 255, 255, 0.80);
                border: 1px solid rgba(255, 255, 255, 0.95);
                box-shadow: 0px 0px 8px 0px rgba(52, 54, 57, 0.12);
                backdrop-filter: blur(20px);
                border-radius: 16px;
                width: 100%;
                max-width: ${({ isCustomTheme }) => (isCustomTheme ? '400px' : '448px')};
                min-height: unset;
                padding-top: 54px;
                padding-bottom: 54px;

                ${({ isCustomTheme }) =>
                    isCustomTheme &&
                    css`
                        &:first-child {
                            h5.ant-typography {
                                font-size: 14px;
                                color: var(--text-color);
                            }
                        }
                    `}
            }

            .ant-typography.title-login {
                margin-bottom: 24px !important;
                font-weight: bold;
                text-align: center;
                color: var(--ant-primary-color-active);
            }

            .WP-login__row__remember {
                margin-top: -4px;

                > .ant-col:first-child > .ant-form-item {
                    margin-bottom: 0;
                }

                &__forgot {
                    display: flex;
                    justify-content: end;
                     margin-bottom: 22px;
                    span {
                     font-size: 12px;
                    } 
                }
            }

            .form-login-actions__row__btn__submit {
                justify-content: center;
                margin-top: 16px;
                margin-bottom: 0px;

                > .ant-col {
                    text-align: center;
                }

                ${({ lg }) =>
                    !lg &&
                    css`
                        margin-bottom: 8px;

                        &__login {
                            margin-bottom: 16px;
                        }
                    `}
            }

            .text-wrapper .text {
                background: rgba(255, 255, 255, 0.3);
                border-radius: var(--border-radius-base);
            }

            &__body {
                padding: 0 24px;
                .WP-login__form {
                    form .input-accname-wrapper:not(.hide) {
                        height: 44px;
                    }

                    &__forgot {
                        &__text {
                            display: block;
                            text-align: center;
                            margin-bottom: 24px;
                        }
                        &__row__action {
                            align-items: center;
                        }
                    }

                    &__title__login {
                        color: var(--ant-primary-color-active);
                        font-size: 24px;
                        text-align: center;
                    }

                    .ant-form-item-label {
                        display: none;
                    }

                    .ant-form-item {
                        margin-bottom: 20px;
                    }

                    .ant-input-affix-wrapper .ant-input {
                        padding: 0;
                        border-radius: 0;
                    }

                    ${({ lg }) => {
                        const additionalHeight = !lg ? 0 : 4;

                        return css`
                            ${!lg &&
                            css`
                                &__title__login {
                                    display: none;
                                }
                            `}

                            .ant-input,
                            .ant-input-affix-wrapper,
                            .ant-select-selector {
                                padding: 8px 12px;
                                border-radius: 12px;
                                font-size: 16px;
                            }

                            .ant-select-selector {
                                &,
                                .ant-select-selection-search-input {
                                    height: ${40 + additionalHeight}px;
                                }
                                .ant-select-selection-placeholder,
                                .ant-select-selection-item {
                                    line-height: ${24 - additionalHeight}px;
                                }
                            }

                            .ant-btn.ant-btn-primary {
                                height: ${40 + additionalHeight * 2}px;
                                width: 100%;
                            }
                        `;
                    }}
                }
            }

            ${() => {
                // const { formLoginPosition, staticContent } =
                //   useLoginLayoutCustomContext();
                return css`
                    justify-content: center;
                `;
            }}
        }

        .WP-login__footer {
            width: 100%;
            display: flex;
            padding: 0px 48px;
            font-size: 12px;
            ${() => {
                // const { viewCopyright, colorCopyright, copyrightPosition } =
                //   useLoginLayoutCustomContext();

                return css`
                    justify-content: end;

                    &__text {
                        display: block;
                        color: var(--gray);
                    }
                `;
            }}
            ${({ lg }) =>
                !lg &&
                css`
                    padding: 0px 0px;
                    justify-content: center;
                `}
        }

        &,
        #sf7-login-container,
        #sf7-login-container > .ant-row {
            transition: all cubic-bezier(0.43, -0.13, 0.08, 1.16) 0.3s;
        }
    }

    .change-pass-btn-col {
        display: flex;
        align-items: center;
        order: 1;

        ${({ lg }) =>
            !lg &&
            css`
                order: 0;
                justify-content: center;
            `}
    }

    .change-pass-back-col {
        display: flex;
        align-items: center;
        order: 0;
        justify-content: flex-start;

        ${({ lg }) =>
            !lg &&
            css`
                order: 1;
                justify-content: center;
            `}
    }
`;
