import { createContext, useContext } from "react";

type WPThemeSelectorContextValue = {
  isCustomThemeActive?: boolean;
  setIsCustomThemeActive?: (value: boolean) => void;
  checkIsCustomThemeActive?: () => boolean;
  applyCustomTheme?: (
    param: {
      primaryColor: string | null;
      favIconUrl: string | null;
      applicationLogoUrl: string | null;
      applicationLogoUrlSmall: string | null;
      fontFamily: string | null;
      fontFamilyTh: string | null;
      fontFamilyCh: string | null;
    } | null
  ) => void;
  disableCustomTheme?: () => void;
};

const contextInitialValue: WPThemeSelectorContextValue = {
  checkIsCustomThemeActive: () => false,
  applyCustomTheme: () => false,
  disableCustomTheme: () => false,
  isCustomThemeActive: false,
  setIsCustomThemeActive: () => false,
};

export const useThemeSelectorCtx = () => useContext(WPThemeSelectorContext);

const WPThemeSelectorContext = createContext(contextInitialValue);

export default WPThemeSelectorContext;
