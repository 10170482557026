import { Button, Result } from 'antd';
import { useTranslate } from '@/lib/hooks/useResourceKey';


export default function NotFound() {
  const LabelBacktohome = useTranslate('Backtohome', 'Back to home');
  const LabelSorryPageNotFound = useTranslate('SorryPageNotFound', 'Sorry, Page not found');
  
  return (
    <div style={{ maxWidth: 800, margin: 'auto' }}>
      <Result
        status={404}
        title={404}
        subTitle={LabelSorryPageNotFound}
        extra={<Button type="primary" 
          onClick={() =>window.location.href = `${process.env.VITE_APP_VIRTUAL_PATH || ''}/standard/home`}
        >
          {LabelBacktohome}
        </Button>}
      />
    </div>
  )
}
