import { useTranslate } from '@/lib/hooks/useResourceKey';
import { Modal, Typography } from 'antd';
import { useEffect } from 'react';
import { useBlocker } from 'react-router-dom';

const WPFormLeaveGuard = ({ block = false }: { block: boolean }) => {
  const labelAreYouSureWantToLeaveThisPage = useTranslate(
    'AreYouSureWantToLeaveThisPage',
    'Are you sure you want to leave this page'
  );
  const labelAnyUnsavedChangesWillBeLost = useTranslate(
    'AnyUnsavedChangesWillBeLost',
    'Any unsaved changes will be lost'
  );
  const labelLeaveThisPage = useTranslate('LeaveThisPage', 'Leave this page');
  const labelStayOnPage = useTranslate('StayOnPage', 'Stay on page');

  const blocker = useBlocker(() => block);

  const handleConfirmNavigationClick = () => {
    if (blocker && blocker.proceed) {
      blocker.proceed();
    }
  };

  const confirm = () => {
    Modal.confirm({
      title: (
        <Typography.Text strong>
          {labelAreYouSureWantToLeaveThisPage}
        </Typography.Text>
      ),
      content: (
        <div>
          <Typography.Text>{labelAnyUnsavedChangesWillBeLost}.</Typography.Text>
        </div>
      ),
      okText: labelLeaveThisPage,
      cancelText: labelStayOnPage,
      onOk: handleConfirmNavigationClick,
      okButtonProps: {
        shape: 'round',
      },
      cancelButtonProps: {
        shape: 'round',
      },
    });
  };

  useEffect(() => {
    if (blocker.state === 'blocked') {
      confirm();
    }
  }, [blocker]);

  if (!block) return null;
};

export default WPFormLeaveGuard;
