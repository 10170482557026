import { useStoreSelector } from '@/hooks/useStore';
import LoadingComponent from '@/lib/components/loading/LoadingComponent';
import { PersistState } from '@/lib/redux/reducers/persistReducer';
import { Suspense } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';

const DEFAULT_PATH_LIST = ['/standard/home', '/standard/inbox'];

const WPAccess = () => {
    const { modeAccessList = [] } = useStoreSelector((state: any) => state.persist);
    const { pathname: currentPath } = useLocation();

    return (
        <>
            {DEFAULT_PATH_LIST.some((path) => currentPath.includes(path) || currentPath === path) ||
            (modeAccessList as PersistState['modeAccessList']).some(
                (item) => item.path.replace(/^\/|\/$/g, '') === currentPath.replace(/^\/|\/$/g, '')
            ) ? (
                <Suspense fallback={<LoadingComponent />}>
                    <Outlet />
                </Suspense>
            ) : (
                <Navigate to="/standard/home" />
            )}
        </>
    );
};

export default WPAccess;
