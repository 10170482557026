import { applyMiddleware, compose, combineReducers } from 'redux';
import thunk from 'redux-thunk';
import { configureStore, Tuple } from '@reduxjs/toolkit'

// import { persistStore, persistReducer } from 'redux-persist';
import reducers  from './reducers';
export const joinStore = (custom) => {
    const customR = custom !== undefined ? { ...custom } : {};
    const combine = combineReducers({ ...reducers, ...customR });

    const store = configureStore({
        reducer: combine,
    })   
    return store
};
export default joinStore;
