import { useEffect, useState } from 'react';
import { ConfigProvider } from 'antd';
// import useListenSettingThemesMessage, {
//   checkIsInSettingThemes,
// } from "pages/setting/setting-themes/helpers/useListenSettingThemesMessage";
import { rootCssVar } from '@/helpers/utilities';
import customThemeRepository from '@/components/global/wp-theme-selector/helpers/customThemeRepository';

const customThemeRepo = customThemeRepository();

const syncMainCssVarPrimaryColor = () => {
    document.documentElement.style.setProperty('--primary-color', rootCssVar('--ant-primary-color'));
    document.documentElement.style.setProperty('--primary-color-active', rootCssVar('--ant-primary-color-active'));
};

const syncMainFontFamily = (fontFamily: string, fontFamilyTh: string, fontFamilyCh: string) => {
    if (fontFamily) document.documentElement.style.setProperty('--font-family', fontFamily);
    if (fontFamilyTh) document.documentElement.style.setProperty('--font-family-th', fontFamilyTh);
    if (fontFamilyCh) document.documentElement.style.setProperty('--font-family-ch', fontFamilyCh);
};

const applyFavIcon = (favIconUrl: string | null) => {
    const link = document.querySelector<HTMLLinkElement>("link[rel='icon']");
    if (link) {
        link.href = favIconUrl || '/favicon.png';
    }
};

function useCustomTheme({
    staticTheme,
    isSSOActive,
    originApp,
}: {
    staticTheme?: Record<string, unknown>;
    isSSOActive?: boolean;
    originApp?: string;
} = {}) {
    const [isCustomThemeActive, setIsCustomThemeActive] = useState(false);

    const applyCustomTheme = (
        param: {
            primaryColor?: string | null;
            favIconUrl?: string | null;
            applicationLogoUrl?: string | null;
            applicationLogoUrlSmall?: string | null;
            fontFamily?: string | null;
            fontFamilyTh?: string | null;
            fontFamilyCh?: string | null;
        } | null = null
    ) => {
        if (!param) return;

        // const inSettingThemes = checkIsInSettingThemes();
        const inSettingThemes = false;

        if (!inSettingThemes) {
            customThemeRepo.setFavIconUrl(param.favIconUrl);
            customThemeRepo.setPrimaryColor(param.primaryColor || '');
            customThemeRepo.setThemeActive(true);
            customThemeRepo.setApplicationLogoUrl(param.applicationLogoUrl);
            customThemeRepo.setApplicationLogoUrlSmall(param.applicationLogoUrlSmall);
            customThemeRepo.setFontFamily(param.fontFamily, param.fontFamilyTh, param.fontFamilyCh);
        }
        ConfigProvider.config({
            theme: {
                ...staticTheme,
                ...(param.primaryColor && {
                    primaryColor: param.primaryColor,
                }),
            },
        });
        syncMainFontFamily(param.fontFamily || '', param.fontFamilyTh || '', param.fontFamilyCh || '');

        syncMainCssVarPrimaryColor();
        applyFavIcon(param.favIconUrl || '');
        setIsCustomThemeActive(true);
    };

    const getApplicationLogoSmall = () => {
        return customThemeRepo.getOrDefault().applicationLogoUrlSmall;
    };

    const getApplicationLogo = () => {
        return customThemeRepo.getOrDefault().applicationLogoUrl;
    };

    const disableCustomTheme = () => {
        if (staticTheme?.primaryColor) {
            ConfigProvider.config({ theme: staticTheme });
        }

        syncMainCssVarPrimaryColor();
        applyFavIcon(null);
        customThemeRepo.setThemeActive(false);
        setIsCustomThemeActive(false);
    };

    //   useListenSettingThemesMessage({
    //     onReceiveMessage(data) {
    //       if (Object.keys(data).length > 0) {
    //         applyCustomTheme({
    //           primaryColor: data?.primaryColor,
    //           favIconUrl: data?.favIconUrl,
    //           fontFamily: data?.fontFamily,
    //           fontFamilyCh: data?.fontFamilyCh,
    //           fontFamilyTh: data?.fontFamilyTh,
    //         });
    //       }
    //     },
    //   });

    useEffect(() => {
        const { isThemeActive, primaryColor, favIconUrl, applicationLogoUrl, applicationLogoUrlSmall, fontFamily, fontFamilyTh, fontFamilyCh } =
            customThemeRepo.getOrDefault();

        if (isThemeActive && primaryColor && !isSSOActive) {
            applyCustomTheme({
                primaryColor,
                favIconUrl,
                applicationLogoUrl,
                applicationLogoUrlSmall,
                fontFamily,
                fontFamilyTh,
                fontFamilyCh,
            });
        } else if (staticTheme) {
            disableCustomTheme();
        }
    }, [JSON.stringify(staticTheme)]);

    useEffect(() => {
        if (isSSOActive)
            switch (originApp) {
                case 'sfgo':
                    applyCustomTheme({ primaryColor: '#f68a22' });
                    break;
                case 'humatrix':
                    applyCustomTheme({ primaryColor: '#0d54d9' });

                    break;
                default:
                    applyCustomTheme({ primaryColor: '#395ebc' });
            }
    }, [originApp]);

    return {
        applyCustomTheme,
        setIsCustomThemeActive,
        isCustomThemeActive,
        disableCustomTheme,
        getApplicationLogo,
        getApplicationLogoSmall,
    };
}

export default useCustomTheme;
