const cachingHelper = {
  setObject(key: string, value: object = {}) {
    try {
      localStorage.setItem(key, JSON.stringify(value));
    } catch (e) {
      console.error(e);
    }
  },

  getObject(key: string) {
    try {
      const storedValue = localStorage.getItem(key);
      return storedValue ? JSON.parse(storedValue) : {};
    } catch (e) {
      console.error(e);
      return {};
    }
  },
};

function generateCacheCrud(key: string) {
  return () => {
    const get = () => cachingHelper.getObject(key);

    const set = (value: object) => cachingHelper.setObject(key, value);

    const update = (value: object) => {
      const prevValue = get();
      set({ ...prevValue, ...value });
    };

    const remove = () => localStorage.removeItem(key);

    return {
      value: get(),
      get,
      set,
      update,
      remove,
    };
  };
}

export default generateCacheCrud;
