import { LoginLayoutCustomProps, LoginThemesSelectorProps } from '../types/loginType';
import CustomLayout from './CustomLayout';
import LoginLayoutCustomContext from '../contexts/LoginLayoutCustomContext';
import React from 'react';

const LoginThemesLayout: React.FC<LoginThemesSelectorProps & LoginLayoutCustomProps> = ({ children, ...props }) => {
    return (
        <LoginLayoutCustomContext.Provider value={props}>
            <CustomLayout>{children}</CustomLayout>
        </LoginLayoutCustomContext.Provider>
    );
};

export default React.memo(LoginThemesLayout);
