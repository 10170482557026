import React, { createContext, useState } from 'react';

// Define the shape of the context data
interface LoginPinContextProps {
  isForceChangePinVisible: boolean;
  setIsForceChangePinVisible: React.Dispatch<React.SetStateAction<boolean>>;
  oldPin: string | undefined;
  setOldPin: React.Dispatch<React.SetStateAction<string | undefined>>;
  isForgotPinVisible: boolean;
  setIsForgotPinVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

// Create the context with default values
export const LoginPinContext = createContext<LoginPinContextProps>({
  isForceChangePinVisible: false,
  setIsForceChangePinVisible: () => {},
  oldPin: undefined,
  setOldPin: () => {},
  isForgotPinVisible: false,
  setIsForgotPinVisible: () => {},
});

// Provider component to wrap around components that need access to the context
export const LoginPinProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  // State to manage the visibility of the force change PIN modal
  const [isForceChangePinVisible, setIsForceChangePinVisible] = useState(false);
  // State to store the old PIN
  const [oldPin, setOldPin] = useState<string | undefined>();

  const [isForgotPinVisible, setIsForgotPinVisible] = useState(false);

  // Return the context provider with the necessary values

  return (
    <LoginPinContext.Provider
      value={{
        isForceChangePinVisible,
        setIsForceChangePinVisible,
        oldPin,
        setOldPin,
        isForgotPinVisible,
        setIsForgotPinVisible,
      }}
    >
      {children}
    </LoginPinContext.Provider>
  );
};
