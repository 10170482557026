import { Button, Flex, Form, Input, Typography } from 'antd';
import React, { useEffect, useState } from 'react';

import InputPin from '../../controls/InputPin';
import ShowToastNotificationResult from '../../show-toast-notification-result/ShowToastNotificationResult';
import { useTranslate } from '../../../hooks/useResourceKey';

interface OTPInputProps {
  referCode: string;
  requestOTP: () => void;
  expireIn: number;
  resendOTPIn: number;
  isResendDisabled?: boolean;
}

const OTPInput: React.FC<OTPInputProps> = ({
  referCode,
  requestOTP,
  expireIn,
  resendOTPIn,
}) => {
  const labelRef = useTranslate('Ref', 'Ref');
  const labelExpireIn = useTranslate('ExpireIn', 'Expire in');
  const labelOTP = useTranslate('OTP', 'OTP');
  const labelOTPRequired = useTranslate('OTPRequired', 'OTP is required');
  const labelNumberOnly = useTranslate('NumberOnly', 'OTP must be a number');
  const labelResendOTP = useTranslate('ResendOTP', 'Resend OTP');
  const labelError = useTranslate('Error', 'Error');
  const labelFailedToSendOTP = useTranslate(
    'FailedToSendOTP',
    'Failed to send OTP'
  );

  const [expireTime, setExpireTime] = useState<number>(expireIn);
  const [resendOTPTime, setResendOTPTime] = useState<number>(resendOTPIn);
  const [resendDisabled, setResendDisabled] = useState<boolean>(true);

  const formatTime = (seconds: number) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${String(minutes).padStart(2, '0')}:${String(
      remainingSeconds
    ).padStart(2, '0')}`;
  };

  useEffect(() => {
    setExpireTime(expireIn);
  }, [expireIn]);

  useEffect(() => {
    setResendDisabled(true);
    setResendOTPTime(resendOTPIn);
  }, [resendOTPIn]);

  useEffect(() => {
    // OTP expire timer interval
    if (expireTime > 0) {
      const interval = setInterval(() => {
        setExpireTime((prevExpireIn) => {
          if (prevExpireIn === 1) {
            setResendDisabled(false);
          }
          return prevExpireIn - 1;
        });
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [expireTime]);

  useEffect(() => {
    // Resend OTP timer interval
    if (resendOTPTime > 0) {
      const interval = setInterval(() => {
        setResendOTPTime((prevTime) => {
          if (prevTime === 1) {
            setResendDisabled(false);
          }
          return prevTime - 1;
        });
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [resendOTPTime]);

  const handleResendOTP = () => {
    try {
      requestOTP();
    } catch (error) {
      ShowToastNotificationResult({
        type: 'error',
        title: labelError,
        subtitle: labelFailedToSendOTP,
        autoClose: true,
        autoCloseSecond: 3,
      });
    }
  };

  return (
    <Form.Item>
      <Flex justify="space-between">
        <Typography>{`${labelOTP} (${labelRef}: ${referCode})`}</Typography>
        <Typography>
          {labelExpireIn} {formatTime(expireTime)}
        </Typography>
      </Flex>
      <Flex justify="center">
        <Form.Item
          noStyle
          name="otp"
          label={labelOTP}
          rules={[
            { required: true, message: labelOTPRequired },
            { pattern: /^\d+$/, message: labelNumberOnly },
            { len: 6, message: 'OTP must be 6 digits' },
          ]}
        >
          <InputPin autoFocus length={6} />
        </Form.Item>
      </Flex>
      <Flex justify="flex-end">
        <Button
          type="link"
          danger
          onClick={handleResendOTP}
          style={{ padding: 0, textDecoration: 'underline' }}
        >
          {formatTime(resendOTPTime)} {labelResendOTP}
        </Button>
      </Flex>
    </Form.Item>
  );
};

export default OTPInput;
