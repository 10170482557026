import React, { useContext } from 'react';

import { Button } from 'antd';
import ForgotPinModal from './ForgotPinModal';
import { LoginPinContext } from '../login-pin/context/LoginPinContext';
import { useTranslate } from '@/lib/hooks/useResourceKey';

const ForgotPinButton = () => {
  const { isForgotPinVisible, setIsForgotPinVisible } =
    useContext(LoginPinContext);
  const labelForgotPin = useTranslate('ForgotPin', 'Forgot PIN');

  const toggleForgotPinModal = () => {
    setIsForgotPinVisible(!isForgotPinVisible);
  };

  return (
    <>
      <Button type="link" size="small" onClick={() => toggleForgotPinModal()}>
        {labelForgotPin}
      </Button>
      <ForgotPinModal />
    </>
  );
};
export default ForgotPinButton;
