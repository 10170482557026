import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import useCookies from '@/lib/hooks/useCookies';

const useCheckIsLogin = () => {
    const navigate = useNavigate();
    const { getMainCookieKeys, deleteCookies } = useCookies();

    const removeAllCookies = () => {
        deleteCookies('main');
    };

    const checkLogin = () => {
        try {
            const arrMainCookies = getMainCookieKeys();
            if (arrMainCookies?.length) {
                navigate('/standard/home');
                return;
            }
            removeAllCookies();
        } catch (err) {
            console.error(err);
            removeAllCookies();
        }
    };

    useEffect(() => {
        checkLogin();
    }, []);
};

export default useCheckIsLogin;
