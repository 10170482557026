import generateCacheCrud from "@/helpers/generateCacheCrud";

const cachedCustomThemeCrud = generateCacheCrud("WP_CUSTOM_THEME")();

type CustomTheme = {
  primaryColor: string | null;
  favIconUrl: string | null;
  isThemeActive: boolean;
  applicationLogoUrl: string | null;
  applicationLogoUrlSmall: string | null;
  fontFamily: string | null;
  fontFamilyTh: string | null;
  fontFamilyCh: string | null;
};

export const initialCustomTheme: CustomTheme = {
  primaryColor: null,
  isThemeActive: false,
  favIconUrl: null,
  applicationLogoUrl: null,
  applicationLogoUrlSmall: null,
  fontFamily: null,
  fontFamilyTh: null,
  fontFamilyCh: null,
};

function customThemeRepository() {
  const setPrimaryColor = (color: string) => {
    if (color) cachedCustomThemeCrud.update({ primaryColor: color });
  };

  const setFavIconUrl = (favIconUrl: string | null = null) => {
    cachedCustomThemeCrud.update({ favIconUrl });
  };

  const setApplicationLogoUrlSmall = (
    applicationLogoUrlSmall: string | null = null
  ) => {
    cachedCustomThemeCrud.update({ applicationLogoUrlSmall });
  };

  const setApplicationLogoUrl = (applicationLogoUrl: string | null = null) => {
    cachedCustomThemeCrud.update({ applicationLogoUrl });
  };

  const setFontFamily = (
    fontFamily: string | null = null,
    fontFamilyTh: string | null = null,
    fontFamilyCh: string | null = null
  ) => {
    cachedCustomThemeCrud.update({ fontFamily, fontFamilyTh, fontFamilyCh });
  };

  const setThemeActive = (isActive: boolean) => {
    cachedCustomThemeCrud.update({ isThemeActive: isActive });
  };

  const initDefault = () => {
    const cached = cachedCustomThemeCrud.get();
    if (Object.keys(cached).length > 0) return;

    cachedCustomThemeCrud.set(initialCustomTheme);
  };

  const getOrDefault = () => {
    const cached: CustomTheme = cachedCustomThemeCrud.get();
    if (Object.keys(cached || {}).length > 0) return cached;

    cachedCustomThemeCrud.set(initialCustomTheme);
    return initialCustomTheme;
  };

  const checkIsCustomThemeActive = () => {
    const { isThemeActive } = getOrDefault();
    return isThemeActive;
  };

  return {
    ...cachedCustomThemeCrud,
    setPrimaryColor,
    setFavIconUrl,
    setThemeActive,
    setApplicationLogoUrl,
    setApplicationLogoUrlSmall,
    getOrDefault,
    initDefault,
    checkIsCustomThemeActive,
    setFontFamily,
  };
}

export default customThemeRepository;
