interface AnyObject {
  [key: string]: any;
}

export function findInNestedArray(
  arr: AnyObject[],
  targetKey: string,
  targetValue: any
): AnyObject | null {
  for (const element of arr) {
    if (typeof element === 'object' && !Array.isArray(element)) {
      if (element[targetKey] && element[targetKey].includes(targetValue)) {
        return element;
      }
      if (element.children && Array.isArray(element.children)) {
        const result = findInNestedArray(
          element.children,
          targetKey,
          targetValue
        );
        if (result) {
          return result;
        }
      }
    }
  }
  return null;
}
