import { AuthFormType, LoginStateContextValue } from '../types/loginType';
import { createContext, useContext, useState } from 'react';

export const LoginStateContext = createContext<LoginStateContextValue | undefined>(undefined);

export const LoginStateProvider = ({ children }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [errMessage, setErrMessage] = useState<string | null>(null);
    const [isChangeAccount, setIsChangeAccount] = useState(false);
    const [isClientDomain, setIsClientDomain] = useState(false);
    const [isRestrictLogin, setIsRestrictLogin] = useState(false);
    const [isRestrictLookedUp, setIsRestrictLookedUp] = useState(false);
    const [isInstanceLoginConfig, setIsInstanceLoginConfig] = useState(false);
    const [selectedFormType, setSelectedFormType] = useState<AuthFormType>('login');
    const [ssoProviders, setSsoProviders] = useState([]);
    const [instancesOptions, setInstancesOptions] = useState([]);
    const [forgotPasswordModalVisible, setForgotPasswordModalVisible] = useState(false);

    const toggleForgotPasswordModal = () => {
        setForgotPasswordModalVisible((prev) => !prev);
    };

    const value: LoginStateContextValue = {
        isLoading,
        errMessage,
        isChangeAccount,
        isClientDomain,
        isRestrictLogin,
        isRestrictLookedUp,
        isInstanceLoginConfig,
        selectedFormType,
        ssoProviders,
        instancesOptions,
        forgotPasswordModalVisible,
        action: {
            setIsLoading,
            setIsClientDomain,
            setIsInstanceLoginConfig,
            setErrMessage,
            setIsRestrictLookedUp,
            setSelectedFormType,
            toggleForgotPasswordModal,
        },
    };

    return <LoginStateContext.Provider value={value}>{children}</LoginStateContext.Provider>;
};

export const useLoginState = () => {
    const context = useContext(LoginStateContext);
    if (context === undefined) {
        throw new Error('useLoginState must be used within a LoginStateProvider');
    }
    return context;
};
