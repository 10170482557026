import { createContext, useContext } from "react";
import { FormLoginValue, LoginData } from '../types/loginType';
import { FormInstance } from 'antd/es/form';

interface AuthContextType {
  isLoginLoading: boolean;
  onLoginSuccess: (loginResponse:LoginData, accountName:FormLoginValue) => void,
  form: FormInstance;
}

const contextInitialValue:AuthContextType = {
  isLoginLoading: false,
  onLoginSuccess: (loginResponse, accountName) => {},
  form: {} as FormInstance,
}

const LoginFormContext = createContext(contextInitialValue)

export const useLoginFormContextCxt = () => useContext(LoginFormContext)

export default LoginFormContext;
