import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

const languageInstance = i18n.createInstance();

languageInstance.use(initReactI18next).init({
  ns: 'translation',
  lng: localStorage.getItem('language') || 'en',
  fallbackLng: 'en',
  react: {
    useSuspense: true,
  },
  interpolation: {
    escapeValue: false,
  },
});

export default languageInstance;
