import { useMemo } from 'react';
import dynamicIcon, { CustomIconComponentProps } from '.';

export const ResultScoreSVG = (props: CustomIconComponentProps) => {
    const svg = (
        <svg width="160" height="160" viewBox="0 0 160 160" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_16258_97607)">
                <mask id="mask0_16258_97607" style={{ maskType: 'luminance' }} maskUnits="userSpaceOnUse" x="0" y="0" width="160" height="160">
                    <path d="M160 0H0V160H160V0Z" fill="white" />
                </mask>
                <g mask="url(#mask0_16258_97607)">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M79.7768 80.0077L76.9849 0.0563364L77.4306 0.0407715L80.2225 79.9921L79.7768 80.0077Z" fill="#A2D4F4" />
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M79.7774 80.0154L74.1968 0.210066L74.6417 0.178955L80.2223 79.9843L79.7774 80.0154Z" fill="#A2D4F4" />
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M79.7774 80.0232L71.4155 0.4619L71.8591 0.415283L80.2209 79.9766L79.7774 80.0232Z" fill="#A2D4F4" />
                </g>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M79.7793 80.031L68.6455 0.809629L69.0872 0.747559L80.221 79.9689L79.7793 80.031Z" fill="#A2D4F4" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M79.7804 80.0386L65.8887 1.25396L66.3279 1.17651L80.2197 79.9612L79.7804 80.0386Z" fill="#A2D4F4" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M79.782 80.0457L63.1489 1.79439L63.5852 1.70166L80.2182 79.953L79.782 80.0457Z" fill="#A2D4F4" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M79.7839 80.0547L60.4302 2.43065L60.8629 2.32275L80.2166 79.9468L79.7839 80.0547Z" fill="#A2D4F4" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M79.7858 80.0615L57.7349 3.16077L58.1636 3.03784L80.2145 79.9386L79.7858 80.0615Z" fill="#A2D4F4" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M79.7873 80.069L55.0664 3.98425L55.4906 3.84644L80.2115 79.9312L79.7873 80.069Z" fill="#A2D4F4" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M79.7903 80.0762L52.4287 4.90083L52.8478 4.74829L80.2093 79.9237L79.7903 80.0762Z" fill="#A2D4F4" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M79.7924 80.0835L49.8242 5.90877L50.2377 5.7417L80.2059 79.9165L79.7924 80.0835Z" fill="#A2D4F4" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M79.7961 80.0908L47.2573 7.00733L47.6648 6.82593L80.2035 79.9094L79.7961 80.0908Z" fill="#A2D4F4" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M79.7994 80.0978L44.73 8.19429L45.1308 7.99878L80.2002 79.9022L79.7994 80.0978Z" fill="#A2D4F4" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M79.8025 80.1046L42.2451 9.46915L42.6389 9.25977L80.1963 79.8953L79.8025 80.1046Z" fill="#A2D4F4" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M79.8066 80.1107L39.8066 10.8292L40.1929 10.6062L80.1929 79.8877L79.8066 80.1107Z" fill="#A2D4F4" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M79.8108 80.1189L37.4175 12.2747L37.7957 12.0383L80.189 79.8825L79.8108 80.1189Z" fill="#A2D4F4" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M79.8153 80.1248L35.0801 13.8019L35.4498 13.5525L80.1851 79.8754L79.8153 80.1248Z" fill="#A2D4F4" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M79.8194 80.1312L32.7969 15.4096L33.1577 15.1475L80.1802 79.869L79.8194 80.1312Z" fill="#A2D4F4" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M79.8245 80.1373L30.5713 17.0964L30.9227 16.8218L80.1759 79.8627L79.8245 80.1373Z" fill="#A2D4F4" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M79.8294 80.1433L28.4062 18.8597L28.7479 18.573L80.171 79.8566L79.8294 80.1433Z" fill="#A2D4F4" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M79.8342 80.1493L26.3037 20.6976L26.6351 20.3992L80.1656 79.8508L79.8342 80.1493Z" fill="#A2D4F4" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M79.8395 80.1549L24.2671 22.6079L24.5879 22.2981L80.1603 79.8451L79.8395 80.1549Z" fill="#A2D4F4" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M79.8449 80.1603L22.2979 24.5879L22.6077 24.2671L80.1547 79.8395L79.8449 80.1603Z" fill="#A2D4F4" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M79.8506 80.1658L20.3989 26.6354L20.6974 26.304L80.149 79.8344L79.8506 80.1658Z" fill="#A2D4F4" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M79.8562 80.1708L18.5732 28.7477L18.8599 28.406L80.1429 79.8291L79.8562 80.1708Z" fill="#A2D4F4" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M79.8627 80.1764L16.8218 30.9232L17.0964 30.5718L80.1373 79.825L79.8627 80.1764Z" fill="#A2D4F4" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M79.869 80.1805L15.1475 33.1579L15.4096 32.7971L80.1312 79.8197L79.869 80.1805Z" fill="#A2D4F4" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M79.8751 80.1848L13.5522 35.4496L13.8016 35.0798L80.1245 79.8151L79.8751 80.1848Z" fill="#A2D4F4" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M79.8816 80.189L12.0381 37.7957L12.2744 37.4175L80.118 79.8108L79.8816 80.189Z" fill="#A2D4F4" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M79.888 80.1931L10.6064 40.1931L10.8294 39.8069L80.111 79.8069L79.888 80.1931Z" fill="#A2D4F4" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M79.8948 80.197L9.25928 42.6396L9.46866 42.2458L80.1041 79.8032L79.8948 80.197Z" fill="#A2D4F4" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M79.902 80.2005L7.99854 45.1311L8.19404 44.7302L80.0975 79.7996L79.902 80.2005Z" fill="#A2D4F4" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M79.9091 80.2038L6.82568 47.665L7.00708 47.2576L80.0905 79.7964L79.9091 80.2038Z" fill="#A2D4F4" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M79.9165 80.2069L5.7417 50.2387L5.90877 49.8252L80.0835 79.7934L79.9165 80.2069Z" fill="#A2D4F4" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M79.9239 80.2096L4.74854 52.8481L4.90107 52.429L80.0765 79.7905L79.9239 80.2096Z" fill="#A2D4F4" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M79.9303 80.2115L3.84619 55.4906L3.98401 55.0664L80.0681 79.7873L79.9303 80.2115Z" fill="#A2D4F4" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M79.9383 80.2143L3.0376 58.1633L3.16053 57.7346L80.0613 79.7856L79.9383 80.2143Z" fill="#A2D4F4" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M79.9461 80.2164L2.32275 60.8627L2.43065 60.4299L80.054 79.7836L79.9461 80.2164Z" fill="#A2D4F4" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M79.953 80.2182L1.70166 63.5852L1.79439 63.1489L80.0457 79.782L79.953 80.2182Z" fill="#A2D4F4" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M79.9615 80.2197L1.17676 66.3279L1.2542 65.8887L80.0389 79.7804L79.9615 80.2197Z" fill="#A2D4F4" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M79.9689 80.2207L0.747559 69.0869L0.809629 68.6453L80.031 79.7791L79.9689 80.2207Z" fill="#A2D4F4" />
                <mask id="mask1_16258_97607" style={{ maskType: 'luminance' }} maskUnits="userSpaceOnUse" x="0" y="0" width="160" height="160">
                    <path d="M160 0H0V160H160V0Z" fill="white" />
                </mask>
                <g mask="url(#mask1_16258_97607)">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M79.9759 80.2212L0.414551 71.8593L0.461168 71.4158L80.0225 79.7776L79.9759 80.2212Z" fill="#A2D4F4" />
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M79.9845 80.2225L0.179199 74.6419L0.21031 74.197L80.0156 79.7776L79.9845 80.2225Z" fill="#A2D4F4" />
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M79.9917 80.223L0.0410156 77.4311L0.0565806 76.9854L80.0072 79.7773L79.9917 80.223Z" fill="#A2D4F4" />
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M80 80.2231H0V79.7771H80V80.2231Z" fill="#A2D4F4" />
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M80.0072 80.2228L0.0565806 83.0148L0.0410156 82.5691L79.9917 79.7771L80.0072 80.2228Z" fill="#A2D4F4" />
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M80.0156 80.2225L0.21031 85.8031L0.179199 85.3582L79.9845 79.7776L80.0156 80.2225Z" fill="#A2D4F4" />
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M80.0225 80.2219L0.461168 88.5837L0.414551 88.1402L79.9759 79.7783L80.0225 80.2219Z" fill="#A2D4F4" />
                </g>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M80.031 80.2209L0.809629 91.3548L0.747559 90.9131L79.9689 79.7793L80.031 80.2209Z" fill="#A2D4F4" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M80.0389 80.2195L1.2542 94.1113L1.17676 93.672L79.9615 79.7803L80.0389 80.2195Z" fill="#A2D4F4" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M80.0457 80.2182L1.79439 96.8513L1.70166 96.415L79.953 79.782L80.0457 80.2182Z" fill="#A2D4F4" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M80.054 80.2164L2.43065 99.5701L2.32275 99.1374L79.9461 79.7837L80.054 80.2164Z" fill="#A2D4F4" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M80.0613 80.2144L3.16053 102.265L3.0376 101.837L79.9383 79.7856L80.0613 80.2144Z" fill="#A2D4F4" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M80.0681 80.212L3.98401 104.933L3.84619 104.509L79.9303 79.7878L80.0681 80.212Z" fill="#A2D4F4" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M80.0765 80.2096L4.90107 107.571L4.74854 107.152L79.9239 79.7905L80.0765 80.2096Z" fill="#A2D4F4" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M80.0835 80.2067L5.90877 110.175L5.7417 109.761L79.9165 79.7932L80.0835 80.2067Z" fill="#A2D4F4" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M80.0905 80.2038L7.00708 112.743L6.82568 112.335L79.9091 79.7964L80.0905 80.2038Z" fill="#A2D4F4" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M80.0975 80.2004L8.19404 115.27L7.99854 114.869L79.902 79.7996L80.0975 80.2004Z" fill="#A2D4F4" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M80.1041 80.197L9.46866 117.754L9.25928 117.361L79.8948 79.8032L80.1041 80.197Z" fill="#A2D4F4" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M80.111 80.1931L10.8294 120.193L10.6064 119.807L79.888 79.8069L80.111 80.1931Z" fill="#A2D4F4" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M80.118 80.189L12.2744 122.582L12.0381 122.204L79.8816 79.8108L80.118 80.189Z" fill="#A2D4F4" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M80.1245 80.1849L13.8016 124.92L13.5522 124.55L79.8751 79.8152L80.1245 80.1849Z" fill="#A2D4F4" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M80.1312 80.1804L15.4096 127.203L15.1475 126.842L79.869 79.8196L80.1312 80.1804Z" fill="#A2D4F4" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M80.1373 80.1757L17.0964 129.429L16.8218 129.077L79.8627 79.8242L80.1373 80.1757Z" fill="#A2D4F4" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M80.1429 80.1708L18.8599 131.594L18.5732 131.252L79.8562 79.8291L80.1429 80.1708Z" fill="#A2D4F4" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M80.149 80.1657L20.6974 133.696L20.3989 133.365L79.8506 79.8342L80.149 80.1657Z" fill="#A2D4F4" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M80.1547 80.1604L22.6077 135.733L22.2979 135.412L79.8449 79.8396L80.1547 80.1604Z" fill="#A2D4F4" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M80.1603 80.155L24.5879 137.702L24.2671 137.392L79.8395 79.8452L80.1603 80.155Z" fill="#A2D4F4" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M80.1656 80.1493L26.6351 139.601L26.3037 139.303L79.8342 79.8508L80.1656 80.1493Z" fill="#A2D4F4" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M80.171 80.1434L28.7479 141.427L28.4062 141.14L79.8294 79.8567L80.171 80.1434Z" fill="#A2D4F4" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M80.1759 80.1374L30.9227 143.178L30.5713 142.904L79.8245 79.8628L80.1759 80.1374Z" fill="#A2D4F4" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M80.1802 80.131L33.1577 144.853L32.7969 144.59L79.8194 79.8689L80.1802 80.131Z" fill="#A2D4F4" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M80.1851 80.1246L35.4498 146.448L35.0801 146.198L79.8153 79.8752L80.1851 80.1246Z" fill="#A2D4F4" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M80.189 80.1182L37.7957 147.962L37.4175 147.725L79.8108 79.8818L80.189 80.1182Z" fill="#A2D4F4" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M80.1929 80.1114L40.1929 149.394L39.8066 149.171L79.8066 79.8884L80.1929 80.1114Z" fill="#A2D4F4" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M80.1963 80.1046L42.6389 150.74L42.2451 150.531L79.8025 79.8953L80.1963 80.1046Z" fill="#A2D4F4" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M80.2002 80.0979L45.1308 152.001L44.73 151.806L79.7994 79.9023L80.2002 80.0979Z" fill="#A2D4F4" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M80.2035 80.0906L47.6648 153.174L47.2573 152.993L79.7961 79.9092L80.2035 80.0906Z" fill="#A2D4F4" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M80.2059 80.0836L50.2377 154.258L49.8242 154.091L79.7924 79.9165L80.2059 80.0836Z" fill="#A2D4F4" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M80.2093 80.0764L52.8478 155.252L52.4287 155.099L79.7903 79.9238L80.2093 80.0764Z" fill="#A2D4F4" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M80.2115 80.069L55.4906 156.154L55.0664 156.016L79.7873 79.9312L80.2115 80.069Z" fill="#A2D4F4" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M80.2145 80.0614L58.1636 156.962L57.7349 156.839L79.7858 79.9385L80.2145 80.0614Z" fill="#A2D4F4" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M80.2166 80.0539L60.8629 157.678L60.4302 157.57L79.7839 79.946L80.2166 80.0539Z" fill="#A2D4F4" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M80.2182 80.0463L63.5852 158.298L63.1489 158.205L79.782 79.9536L80.2182 80.0463Z" fill="#A2D4F4" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M80.2197 80.0386L66.3279 158.823L65.8887 158.746L79.7804 79.9612L80.2197 80.0386Z" fill="#A2D4F4" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M80.221 80.0311L69.0872 159.252L68.6455 159.19L79.7793 79.969L80.221 80.0311Z" fill="#A2D4F4" />
                <mask id="mask2_16258_97607" style={{ maskType: 'luminance' }} maskUnits="userSpaceOnUse" x="0" y="0" width="160" height="160">
                    <path d="M160 0H0V160H160V0Z" fill="white" />
                </mask>
                <g mask="url(#mask2_16258_97607)">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M80.2209 80.0234L71.8591 159.585L71.4155 159.538L79.7774 79.9768L80.2209 80.0234Z" fill="#A2D4F4" />
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M80.2223 80.0157L74.6417 159.821L74.1968 159.79L79.7774 79.9846L80.2223 80.0157Z" fill="#A2D4F4" />
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M80.2225 80.008L77.4306 159.959L76.9849 159.944L79.7768 79.9924L80.2225 80.008Z" fill="#A2D4F4" />
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M80.2228 80V160H79.7769V80H80.2228Z" fill="#A2D4F4" />
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M80.2226 79.9924L83.0145 159.944L82.5688 159.959L79.7769 80.008L80.2226 79.9924Z" fill="#A2D4F4" />
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M80.2222 79.9846L85.8028 159.79L85.3579 159.821L79.7773 80.0157L80.2222 79.9846Z" fill="#A2D4F4" />
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M80.2214 79.9768L88.5832 159.538L88.1397 159.585L79.7778 80.0234L80.2214 79.9768Z" fill="#A2D4F4" />
                </g>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M80.2209 79.969L91.3548 159.19L90.9131 159.252L79.7793 80.0311L80.2209 79.969Z" fill="#A2D4F4" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M80.2195 79.9612L94.1113 158.746L93.672 158.823L79.7803 80.0386L80.2195 79.9612Z" fill="#A2D4F4" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M80.218 79.9536L96.851 158.205L96.4148 158.298L79.7817 80.0463L80.218 79.9536Z" fill="#A2D4F4" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M80.2164 79.946L99.5701 157.57L99.1374 157.678L79.7837 80.0539L80.2164 79.946Z" fill="#A2D4F4" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M80.2144 79.9385L102.265 156.839L101.837 156.962L79.7856 80.0614L80.2144 79.9385Z" fill="#A2D4F4" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M80.2123 79.9312L104.933 156.016L104.509 156.154L79.7881 80.069L80.2123 79.9312Z" fill="#A2D4F4" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M80.2096 79.9238L107.571 155.099L107.152 155.252L79.7905 80.0764L80.2096 79.9238Z" fill="#A2D4F4" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M80.207 79.9165L110.175 154.091L109.762 154.258L79.7935 80.0836L80.207 79.9165Z" fill="#A2D4F4" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M80.2038 79.9092L112.743 152.993L112.335 153.174L79.7964 80.0906L80.2038 79.9092Z" fill="#A2D4F4" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M80.2007 79.9023L115.27 151.806L114.869 152.001L79.7998 80.0979L80.2007 79.9023Z" fill="#A2D4F4" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M80.197 79.8953L117.754 150.531L117.361 150.74L79.8032 80.1046L80.197 79.8953Z" fill="#A2D4F4" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M80.1929 79.8884L120.193 149.171L119.807 149.394L79.8066 80.1114L80.1929 79.8884Z" fill="#A2D4F4" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M80.1893 79.8818L122.583 147.725L122.204 147.962L79.811 80.1182L80.1893 79.8818Z" fill="#A2D4F4" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M80.1847 79.8752L124.92 146.198L124.55 146.448L79.8149 80.1246L80.1847 79.8752Z" fill="#A2D4F4" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M80.1806 79.8689L127.203 144.59L126.842 144.853L79.8198 80.131L80.1806 79.8689Z" fill="#A2D4F4" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M80.1757 79.8628L129.429 142.904L129.077 143.178L79.8242 80.1374L80.1757 79.8628Z" fill="#A2D4F4" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M80.1708 79.8567L131.594 141.14L131.252 141.427L79.8291 80.1434L80.1708 79.8567Z" fill="#A2D4F4" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M80.1659 79.8508L133.696 139.303L133.365 139.601L79.8345 80.1493L80.1659 79.8508Z" fill="#A2D4F4" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M80.1602 79.8452L135.733 137.392L135.412 137.702L79.8394 80.155L80.1602 79.8452Z" fill="#A2D4F4" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M80.155 79.8396L137.702 135.412L137.392 135.733L79.8452 80.1604L80.155 79.8396Z" fill="#A2D4F4" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M80.149 79.8342L139.601 133.365L139.302 133.696L79.8506 80.1657L80.149 79.8342Z" fill="#A2D4F4" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M80.1431 79.8291L141.426 131.252L141.139 131.594L79.8564 80.1708L80.1431 79.8291Z" fill="#A2D4F4" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M80.1374 79.8242L143.178 129.077L142.904 129.429L79.8628 80.1757L80.1374 79.8242Z" fill="#A2D4F4" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M80.1313 79.8196L144.853 126.842L144.591 127.203L79.8691 80.1804L80.1313 79.8196Z" fill="#A2D4F4" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M80.1249 79.8152L146.448 124.55L146.198 124.92L79.8755 80.1849L80.1249 79.8152Z" fill="#A2D4F4" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M80.1182 79.8108L147.962 122.204L147.725 122.582L79.8818 80.189L80.1182 79.8108Z" fill="#A2D4F4" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M80.1117 79.8069L149.393 119.807L149.17 120.193L79.8887 80.1931L80.1117 79.8069Z" fill="#A2D4F4" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M80.1049 79.8032L150.74 117.361L150.531 117.754L79.8955 80.197L80.1049 79.8032Z" fill="#A2D4F4" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M80.0979 79.7996L152.001 114.869L151.806 115.27L79.9023 80.2004L80.0979 79.7996Z" fill="#A2D4F4" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M80.0906 79.7964L153.174 112.335L152.993 112.743L79.9092 80.2038L80.0906 79.7964Z" fill="#A2D4F4" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M80.0836 79.7932L154.258 109.761L154.091 110.175L79.9165 80.2067L80.0836 79.7932Z" fill="#A2D4F4" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M80.0764 79.7905L155.252 107.152L155.099 107.571L79.9238 80.2096L80.0764 79.7905Z" fill="#A2D4F4" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M80.069 79.7878L156.153 104.509L156.015 104.933L79.9312 80.212L80.069 79.7878Z" fill="#A2D4F4" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M80.0614 79.7856L156.962 101.837L156.839 102.265L79.9385 80.2144L80.0614 79.7856Z" fill="#A2D4F4" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M80.0542 79.7837L157.678 99.1374L157.57 99.5701L79.9463 80.2164L80.0542 79.7837Z" fill="#A2D4F4" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M80.0463 79.782L158.298 96.415L158.205 96.8513L79.9536 80.2182L80.0463 79.782Z" fill="#A2D4F4" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M80.0389 79.7803L158.824 93.672L158.746 94.1113L79.9614 80.2195L80.0389 79.7803Z" fill="#A2D4F4" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M80.0308 79.7793L159.252 90.9131L159.19 91.3548L79.9688 80.2209L80.0308 79.7793Z" fill="#A2D4F4" />
                <mask id="mask3_16258_97607" style={{ maskType: 'luminance' }} maskUnits="userSpaceOnUse" x="0" y="0" width="160" height="160">
                    <path d="M160 0H0V160H160V0Z" fill="white" />
                </mask>
                <g mask="url(#mask3_16258_97607)">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M80.0232 79.7783L159.585 88.1402L159.538 88.5837L79.9766 80.2219L80.0232 79.7783Z" fill="#A2D4F4" />
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M80.0155 79.7776L159.821 85.3582L159.79 85.8031L79.9844 80.2225L80.0155 79.7776Z" fill="#A2D4F4" />
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M80.0078 79.7771L159.958 82.5691L159.943 83.0148L79.9922 80.2228L80.0078 79.7771Z" fill="#A2D4F4" />
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M80 79.7771H160V80.2231H80V79.7771Z" fill="#A2D4F4" />
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M79.9922 79.7773L159.943 76.9854L159.958 77.4311L80.0078 80.223L79.9922 79.7773Z" fill="#A2D4F4" />
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M79.9844 79.7776L159.79 74.197L159.821 74.6419L80.0155 80.2225L79.9844 79.7776Z" fill="#A2D4F4" />
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M79.9766 79.7776L159.538 71.4158L159.585 71.8593L80.0232 80.2212L79.9766 79.7776Z" fill="#A2D4F4" />
                </g>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M79.9688 79.7791L159.19 68.6453L159.252 69.0869L80.0308 80.2207L79.9688 79.7791Z" fill="#A2D4F4" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M79.9614 79.7804L158.746 65.8887L158.824 66.3279L80.0389 80.2197L79.9614 79.7804Z" fill="#A2D4F4" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M79.9536 79.782L158.205 63.1489L158.298 63.5852L80.0463 80.2182L79.9536 79.782Z" fill="#A2D4F4" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M79.9463 79.7836L157.57 60.4299L157.678 60.8627L80.0542 80.2164L79.9463 79.7836Z" fill="#A2D4F4" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M79.9385 79.7856L156.839 57.7346L156.962 58.1633L80.0614 80.2143L79.9385 79.7856Z" fill="#A2D4F4" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M79.9312 79.7873L156.015 55.0664L156.153 55.4906L80.069 80.2115L79.9312 79.7873Z" fill="#A2D4F4" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M79.9238 79.7905L155.099 52.429L155.252 52.8481L80.0764 80.2096L79.9238 79.7905Z" fill="#A2D4F4" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M79.9165 79.7934L154.091 49.8252L154.258 50.2387L80.0836 80.2069L79.9165 79.7934Z" fill="#A2D4F4" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M79.9092 79.7964L152.993 47.2576L153.174 47.665L80.0906 80.2038L79.9092 79.7964Z" fill="#A2D4F4" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M79.9023 79.7996L151.806 44.7302L152.001 45.1311L80.0979 80.2005L79.9023 79.7996Z" fill="#A2D4F4" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M79.8955 79.8032L150.531 42.2458L150.74 42.6396L80.1049 80.197L79.8955 79.8032Z" fill="#A2D4F4" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M79.8887 79.8069L149.17 39.8069L149.393 40.1931L80.1117 80.1931L79.8887 79.8069Z" fill="#A2D4F4" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M79.8818 79.8108L147.725 37.4175L147.962 37.7957L80.1182 80.189L79.8818 79.8108Z" fill="#A2D4F4" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M79.8755 79.8151L146.198 35.0798L146.448 35.4496L80.1249 80.1848L79.8755 79.8151Z" fill="#A2D4F4" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M79.8691 79.8197L144.591 32.7971L144.853 33.1579L80.1313 80.1805L79.8691 79.8197Z" fill="#A2D4F4" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M79.8628 79.825L142.904 30.5718L143.178 30.9232L80.1374 80.1764L79.8628 79.825Z" fill="#A2D4F4" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M79.8564 79.8291L141.139 28.406L141.426 28.7477L80.1431 80.1708L79.8564 79.8291Z" fill="#A2D4F4" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M79.8506 79.8344L139.302 26.304L139.601 26.6354L80.149 80.1658L79.8506 79.8344Z" fill="#A2D4F4" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M79.8452 79.8395L137.392 24.2671L137.702 24.5879L80.155 80.1603L79.8452 79.8395Z" fill="#A2D4F4" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M79.8394 79.8451L135.412 22.2981L135.733 22.6079L80.1602 80.1549L79.8394 79.8451Z" fill="#A2D4F4" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M79.8345 79.8508L133.365 20.3992L133.696 20.6976L80.1659 80.1493L79.8345 79.8508Z" fill="#A2D4F4" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M79.8291 79.8566L131.252 18.573L131.594 18.8597L80.1708 80.1433L79.8291 79.8566Z" fill="#A2D4F4" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M79.8242 79.8627L129.077 16.8218L129.429 17.0964L80.1757 80.1373L79.8242 79.8627Z" fill="#A2D4F4" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M79.8198 79.869L126.842 15.1475L127.203 15.4096L80.1806 80.1312L79.8198 79.869Z" fill="#A2D4F4" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M79.8149 79.8754L124.55 13.5525L124.92 13.8019L80.1847 80.1248L79.8149 79.8754Z" fill="#A2D4F4" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M79.811 79.8825L122.204 12.0383L122.583 12.2747L80.1893 80.1189L79.811 79.8825Z" fill="#A2D4F4" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M79.8066 79.8877L119.807 10.6062L120.193 10.8292L80.1929 80.1107L79.8066 79.8877Z" fill="#A2D4F4" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M79.8032 79.8953L117.361 9.25977L117.754 9.46915L80.197 80.1046L79.8032 79.8953Z" fill="#A2D4F4" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M79.7998 79.9022L114.869 7.99878L115.27 8.19429L80.2007 80.0978L79.7998 79.9022Z" fill="#A2D4F4" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M79.7964 79.9094L112.335 6.82593L112.743 7.00733L80.2038 80.0908L79.7964 79.9094Z" fill="#A2D4F4" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M79.7935 79.9165L109.762 5.7417L110.175 5.90877L80.207 80.0835L79.7935 79.9165Z" fill="#A2D4F4" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M79.7905 79.9237L107.152 4.74829L107.571 4.90083L80.2096 80.0762L79.7905 79.9237Z" fill="#A2D4F4" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M79.7881 79.9312L104.509 3.84644L104.933 3.98425L80.2123 80.069L79.7881 79.9312Z" fill="#A2D4F4" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M79.7856 79.9386L101.837 3.03784L102.265 3.16077L80.2144 80.0615L79.7856 79.9386Z" fill="#A2D4F4" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M79.7837 79.9468L99.1374 2.32275L99.5701 2.43065L80.2164 80.0547L79.7837 79.9468Z" fill="#A2D4F4" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M79.7817 79.953L96.4148 1.70166L96.851 1.79439L80.218 80.0457L79.7817 79.953Z" fill="#A2D4F4" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M79.7803 79.9612L93.672 1.17651L94.1113 1.25396L80.2195 80.0386L79.7803 79.9612Z" fill="#A2D4F4" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M79.7793 79.9689L90.9131 0.747559L91.3548 0.809629L80.2209 80.031L79.7793 79.9689Z" fill="#A2D4F4" />
                <mask id="mask4_16258_97607" style={{ maskType: 'luminance' }} maskUnits="userSpaceOnUse" x="0" y="0" width="160" height="160">
                    <path d="M160 0H0V160H160V0Z" fill="white" />
                </mask>
                <g mask="url(#mask4_16258_97607)">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M79.7783 79.9766L88.1402 0.415283L88.5837 0.4619L80.2219 80.0232L79.7783 79.9766Z" fill="#A2D4F4" />
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M79.7773 79.9845L85.3579 0.179199L85.8028 0.21031L80.2222 80.0156L79.7773 79.9845Z" fill="#A2D4F4" />
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M79.7769 79.9921L82.5688 0.0407715L83.0145 0.0563364L80.2226 80.0077L79.7769 79.9921Z" fill="#A2D4F4" />
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M79.7769 80V0H80.2228V80H79.7769Z" fill="#A2D4F4" />
                </g>
                <path d="M79.9589 128.584C106.814 128.584 128.585 106.814 128.585 79.9586C128.585 53.1033 106.814 31.3328 79.9589 31.3328C53.1035 31.3328 31.333 53.1033 31.333 79.9586C31.333 106.814 53.1035 128.584 79.9589 128.584Z" fill="#FCFDFF" fill-opacity="0.7" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M79.9589 32.6661C53.8399 32.6661 32.6663 53.8397 32.6663 79.9586C32.6663 106.078 53.8399 127.251 79.9589 127.251C106.078 127.251 127.251 106.078 127.251 79.9586C127.251 53.8397 106.078 32.6661 79.9589 32.6661ZM31.333 79.9586C31.333 53.1033 53.1035 31.3328 79.9589 31.3328C106.814 31.3328 128.585 53.1033 128.585 79.9586C128.585 106.814 106.814 128.584 79.9589 128.584C53.1035 128.584 31.333 106.814 31.333 79.9586Z" fill="#FCFDFF" />
            </g>
            <defs>
                <clipPath id="clip0_16258_97607">
                    <rect width="160" height="160" fill="white" />
                </clipPath>
            </defs>
        </svg >

    );

    return props?.withSpan ? (
        <span role="img" aria-label="bookmark" className="anticon">
            {svg}
        </span>
    ) : (
        svg
    );
};



const ResultScore = ({
}
)

export default ResultScore