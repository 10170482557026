import { Button, Result } from 'antd';
import { useTranslate } from '@/lib/hooks/useResourceKey';


export default function ErrorBoundary() {
  const TextSomethingWentwrong = useTranslate('SorrySomethingWentwrong', 'Sorry, Something went wrong');
  const textReload = useTranslate('Reload', 'Reload');
  
  return (
    <div style={{ maxWidth: 800, margin: 'auto' }}>
      <Result
        status={500}
        title={500}
        subTitle={TextSomethingWentwrong}
        extra={<Button type="primary" onClick={() =>window.location.reload()}>{textReload}</Button>}
      />
    </div>
  )
}
