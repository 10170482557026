import { ModeAccess } from '@/lib/interfaces';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface PersistState {
  allMenuPath: string[];
  loadingComponent: boolean;
  modeAccessList: {
    menuCode: string;
    path: string;
    modeAccess: ModeAccess;
  }[];
}

const initialState: PersistState = {
  allMenuPath: [],
  loadingComponent: true,
  modeAccessList: [],
};

export const persistReducer = createSlice({
  name: 'persist',
  initialState,
  reducers: {
    setAllMenuPath: (state, action: PayloadAction<string[]>) => {
      const menuPathSet = new Set(state.allMenuPath);
      const combinedPathSet = new Set([...menuPathSet, ...action.payload]);
      state.allMenuPath = Array.from(combinedPathSet);
    },
    setLoadingComponent: (state, action: PayloadAction<boolean>) => {
      state.loadingComponent = action.payload;
    },
    setModeAccess: (
      state,
      action: PayloadAction<PersistState['modeAccessList']>
    ) => {
      state.modeAccessList = action.payload;
    },
  },
});

export const { setAllMenuPath, setLoadingComponent, setModeAccess } =
  persistReducer.actions;
export default persistReducer.reducer;
