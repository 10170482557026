import React from 'react';
import { useResourceKey } from '../../hooks/useResourceKey';
import { I18nextProvider, useTranslation } from 'react-i18next';
import languageInstance from '../../i18n';

const ResourceDefinitionProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const {
    i18n: { language, addResources, hasResourceBundle },
  } = useTranslation();
  const { fetchResourceKey, getResourceKeys, checkExpiredResourceKey } =
    useResourceKey();

  const manageResourceKeys = async () => {
    const storedResourceKeyList = await getResourceKeys();

    if (!storedResourceKeyList || !checkExpiredResourceKey()) {
      const response = await fetchResourceKey().catch(
        () => storedResourceKeyList || {}
      );
      addResources(language, 'translation', response);
    } else if (
      storedResourceKeyList &&
      !hasResourceBundle(language, 'translation')
    ) {
      addResources(language, 'translation', storedResourceKeyList);
    }
  };

  React.useEffect(() => {
    manageResourceKeys();
  }, [language]);

  return <I18nextProvider i18n={languageInstance}>{children}</I18nextProvider>;
};

export default ResourceDefinitionProvider;
