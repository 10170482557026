import { createContext, useContext } from "react";

interface AuthContextType {
  isLoginLoading: boolean;
  isModalOpen: boolean;
  dispatchIdleTimer: {
    onClearInterval:() => void,
    setAsyncRefreshToken:(value:boolean)=> void
    setLoginLoading:(value:boolean)=> void
    onShowModalReLogin:()=> void
    runtimeInterval:()=> void
  },
  logoutAccount:() => void
}

const contextInitialValue:AuthContextType = {
  isLoginLoading: false,
  isModalOpen: false,
  dispatchIdleTimer: {
    onClearInterval: () => {},
    setAsyncRefreshToken: (value) => {value},
    setLoginLoading: (value) => {value},
    onShowModalReLogin: ()=> {},
    runtimeInterval: ()=> {}
  },
  logoutAccount:()=> {}
} 
 
const AuthContext = createContext(contextInitialValue)

export const useAuthCtx = () => useContext(AuthContext)

export default AuthContext;

