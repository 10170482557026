import Cookies from 'js-cookie';
import { decrypt, encrypt } from '../helpers/cryptography';

const useCookies = () => {
  const createCookies = (
    name: string,
    data: unknown,
    isNeedEncryption: boolean = true,
    cookiesOptions: Cookies.CookieAttributes = {}
  ) => {
    const cookiesData = isNeedEncryption ? encrypt({ value: data }) : data;
    Cookies.set(name, cookiesData as string, cookiesOptions);
  };

  const deleteCookies = (name: string) => Cookies.remove(name);

  const getCookies = (name: string, isEncrypted: boolean) => {
    if (!isEncrypted) return Cookies.get(name);
    return decrypt({ value: Cookies.get(name) });
  };

  const getMainCookieKeys = () => {
    const allCookies = Cookies.get();
    const arrCookiesObjKeys = Object.keys(allCookies);
    return arrCookiesObjKeys?.filter(item => item?.includes('main'));
  };

  const getMainCookie = () => {
    let cookies;
    const mainCookie = getCookies('main', true);
    if (mainCookie) {
      cookies = { ...mainCookie };
    }
    return cookies;
  };

  return {
    createCookies,
    deleteCookies,
    getCookies,
    getMainCookie,
    getMainCookieKeys,
  };
};

export default useCookies;
