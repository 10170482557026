import { useBlocker, useLocation, useNavigate } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';

import LoginPinModal from '@/lib/components/security/login-pin/LoginPinModal';
import { PinType } from '@/lib/components/security/login-pin/utils/enum';
import { findInNestedArray } from '@/lib/helpers/findInNestedArray';
import useCookies from '@/lib/hooks/useCookies';

const LoginPinMiddleware = () => {
    const virtualPath = process.env.VITE_APP_VIRTUAL_PATH || '/';
    const menuList = JSON.parse(localStorage.getItem('menuList') || '[]');
    const { getMainCookie } = useCookies();

    const { pinType } = getMainCookie();
    const navigate = useNavigate();
    const location = useLocation();
    const { deleteCookies } = useCookies();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [pendingLocation, setPendingLocation] = useState<string | null>(null);
    const pageSettingsRef = useRef<any | null>(null);
    const [loginPinCompleted, setLoginPinCompleted] = useState(() => {
        // Check local storage for login PIN completion state
        return localStorage.getItem('loginPinCompleted') === 'true';
    });
    const disableBlockerRef = useRef(false);

    // Block navigation when modal is open or PIN is required
    const blocker = useBlocker(({ currentLocation, nextLocation }) => {
        if (disableBlockerRef.current) {
            disableBlockerRef.current = false;
            return false;
        }
        return shouldInterrupt(nextLocation.pathname);
    });

    // Logic to determine if navigation should be interrupted
    const shouldInterrupt = (path: string) => {
        const actualPath = path.split('/').slice(2).join('/');
        const targetSetting = findInNestedArray(menuList, 'path', actualPath);
        pageSettingsRef.current = targetSetting;
        deleteCookies('pinToken');
        if (targetSetting?.isPin && targetSetting?.isPassword2 && actualPath !== location.pathname && pinType !== PinType.NotDefined) {
            // Reset loginPinCompleted state if the new page requires a PIN
            setLoginPinCompleted(false);
            localStorage.removeItem('loginPinCompleted');
            return true;
        }
        return false;
    };

    // Effect to handle the blocker state
    useEffect(() => {
        if (blocker.state === 'blocked') {
            const actualPath = blocker.location.pathname.startsWith(virtualPath)
                ? blocker.location.pathname.slice(virtualPath.length)
                : blocker.location.pathname;
            setPendingLocation(actualPath);
            setIsModalOpen(true);
        }
    }, [blocker]);

    // Effect to handle page refresh or direct URL access
    useEffect(() => {
        const currentPath = location.pathname.startsWith(virtualPath) ? location.pathname.slice(virtualPath.length) : location.pathname;
        if (shouldInterrupt(currentPath)) {
            setPendingLocation(currentPath);
            setIsModalOpen(true);
        }
    }, [location]);

    // Handle the completion of the PIN form
    const handleComplete = (complete: boolean) => {
        const currentPath = location.pathname.startsWith(virtualPath) ? location.pathname.slice(virtualPath.length) : location.pathname;
        if (complete) {
            setLoginPinCompleted(true);
            localStorage.setItem('loginPinCompleted', 'true'); // Store completion state in local storage
            setIsModalOpen(false);
            if (pendingLocation !== currentPath && pendingLocation !== null) {
                disableBlockerRef.current = true;
                navigate(pendingLocation);
            }
            setPendingLocation(null);
        }
    };

    // Handle the cancellation of the PIN form
    const handleCancel = () => {
        setIsModalOpen(false);
        setPendingLocation(null);
    };

    return (
        <LoginPinModal
            isVisible={isModalOpen}
            onCancel={handleCancel}
            onResponse={(value) => handleComplete(value.isUnlocked)}
            type="Page"
            targetPath={pendingLocation || ''}
        />
    );
};

export default LoginPinMiddleware;
