import { FormInstance } from 'antd';
import OTPInput from '@/lib/components/security/otp-input';
import React from 'react';
import WPForm from '../../wp-form/WPForm';

interface SubmitOTPFormProps {
  formInstance: FormInstance;
  refNo: string;
  requestOTP: () => void;
  resendOTPTime: number;
  expireIn: number;
  onSubmit: (values: any) => void;
}

const SubmitOTPForm: React.FC<SubmitOTPFormProps> = ({
  formInstance,
  refNo,
  requestOTP,
  resendOTPTime,
  expireIn,
  onSubmit,
}) => {
  return (
    <WPForm form={formInstance} layout="vertical" onFinish={onSubmit}>
      <OTPInput
        referCode={refNo}
        requestOTP={requestOTP}
        resendOTPIn={resendOTPTime}
        expireIn={expireIn}
      />
    </WPForm>
  );
};

export default SubmitOTPForm;
